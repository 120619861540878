<section class="banner">
  <div class="banner-box">
    <div class="intro-text">
      <div class="intro-text-box">
        <h1>Lorem Ipsum is simply dummy text</h1>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry
          standard dummy text ever since the 1500s</p>
      </div>
    </div>
    <img src="assets/images/banner.jpg" alt="banner" />
  </div>
</section>