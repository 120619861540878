<p-sidebar [(visible)]="filterSidebar" #op position="right">
    <ng-template pTemplate>
        <div class="p-col-12">
            <div class="p-field p-grid">
                <div class="p-col-12">
    
                    <input type="text" [style]="{ width: '100%' }" pInputText [(ngModel)]="nameFilter" placeholder="Nome" />
                </div>
            </div>
            <div class="p-field p-grid">
                <div class="p-col-12">
                    <input type="text" [style]="{ width: '100%' }" pInputText [(ngModel)]="emailFilter"
                        placeholder="Email" />
                </div>
            </div>

            <div class="p-field p-grid">
                <div class="p-col-12">
                    <input type="text" [style]="{ width: '100%' }" pInputText [(ngModel)]="idFilter"
                        placeholder="ID" />
                </div>
            </div>
    
            <div class="p-field p-grid">
                <div class="p-col-12">
                    <input type="text" [style]="{ width: '100%' }" pInputText [(ngModel)]="accountTypeFilter"
                        placeholder="Account Type" />
                </div>
            </div>
            <div class="p-field p-grid">
                <div class="p-col-6">
                    <button pButton type="reset" class="p-button p-button-secondary btn-block"
                    label="annulla" (click)="reset()">
                </button>
                </div>
    
                <div class="p-col-6">
                    <button pButton type="submit" class="p-button p-button-primary btn-block"
                    label="cerca" (click)="load()">
                </button>
                </div>
    
            </div>
        </div>
        
    </ng-template>
</p-sidebar>

<app-topnav></app-topnav>

<div class="top-main-wrapper">


    <div class="dash-header">
        <div class="dash-title">
    
            <div class="p-field p-grid">
                <div class="p-col-6 p-md-6">
                    <h3>Users</h3>
                </div>
                <div class="p-col-6 p-md-6">
    
                    <button pButton pRipple icon="pi pi-plus" [routerLink]="['/admin/users/create']" label=" aggiungi" class="p-button p-button-primary -mr-2  pull-right"></button>
                    <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf" labe l="Esporta" (click)="exportPdf()" class="p-button-options p-mr-2 pull-right"  tooltipPosition="bottom"></button> -->
                    <button type="text" pButton label="Filter"  icon="pi pi-filter" class="p-button p-button-options p-mr-2 pull-right" (click)="filterSidebar = true"></button>
      
                </div>
            </div>
    
        </div>
    </div>
</div>



<div class="main-inner">





<div class="p-fluid">
    <div class="p-field p-grid">
        <div class="p-col-12 p-md-12">

                <div class="filter-options" *ngIf="nameFilter">

                    <div class="p-field p-grid">

                        <div class="p-col-12 p-md-12">
                            <div class="item-size pull-left">
                                <span class="filter-tag" *ngIf="nameFilter" >Nome: {{nameFilter}} <span class="close"><i class="pi pi-times"  (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="descriptionFilter">Cognome: {{descriptionFilter}} <span class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                            
                            </div>
                        </div>

                     
                    </div>
                </div>

                    <div *ngFor=" let client of clients  | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: count }; let i = index"></div>

                <p-table #dt [columns]="cols" [value]="clients"   [responsive]="false"  [autoLayout]="false"  [paginator]="false" [rows]="10">
    
                
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                        
                            <td>{{rowData.id}}</td>
                                <td><h4 class="p-mb-0 p-mt-0 truncate">{{rowData.display_name}}</h4>
                                <p class="small"></p>
                            </td>
                 
                            <td>
                                {{rowData.u_created_on | date}}
                            </td>
                            <td>
                                {{rowData.user_email}}
                            </td>

                            <td>

                                <p-tag *ngIf="rowData.account_type == 2" severity="default">Pro</p-tag>
                    
                            </td>


                            <td>
                                <p-tag *ngIf="rowData.is_active == 1" severity="success">active</p-tag>

                                <p-tag *ngIf="rowData.is_active == 0" severity="danger">not active</p-tag>
                    
                            </td>
                            <td class="action">
                                <button pButton pRipple icon="pi pi-trash" class="p-button p-button-danger pull-right p-mr-2"
                                (click)="onDelete(rowData.id)"></button>
                                <button pButton pRipple icon="pi pi-pencil" class="p-button p-button-success pull-right p-mr-2"
                                [routerLink]="['/admin/users/edit', rowData.id]"></button>
                            <button pButton pRipple icon="pi pi-eye" class="p-button p-button-primary pull-right p-mr-2"
                                (click)="edit(rowData)"></button>
                                              
                            </td>
                        </tr>
                    </ng-template>
                </p-table> 

                <div class="p-grid">
                    <div class="p-col-12 p-md-4">
                        <p class="p-pt-1">
                            <span class="count-item pull-left">{{pageSize * page - pageSize +1 }} - {{pageSize * page}} di {{count}} risultati</span></p></div>
                    <div class="p-col-12 p-md-8">
                        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true"
                            (pageChange)="handlePageChange($event)">
                        </pagination-controls>
                    </div>
                </div>
              
        </div>

       
    </div>
</div>

</div>
<p-sidebar [(visible)]="productDialog" position="right"  [style]="{width:'50%'}">
    <ng-template pTemplate="content">
        <div class="p-col-12 p-md-12">
        <p-card>

            <strong>{{client.display_name}}</strong>

            <hr>
            <p>{{ client.user_email }}</p>
            <div class="clearfix"></div><br>
            <button pButton pRipple icon="pi pi-pencil" label="Modifica Dati Cliente" class="p-button p-button-primary p-mr-2" [routerLink]="['/admin/users/edit', client.id]"></button>
   


       
    </p-card>    </div>
    </ng-template>

</p-sidebar>




<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No" (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si" (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1"></p-toast>

<p-toast key="myKey2">{{error}}</p-toast>



