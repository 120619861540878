<section class="cmspage mtb-40">
  <div class="container">
    <div class="page-desc" [hidden]="submitted">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <h1>Contact</h1>
          <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
            <div class="form-group">
              <input type="text" name="name" id="name" [(ngModel)]="model.name" class="form-control" placeholder="Name"
                required #name="ngModel">
              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error">
                <div *ngIf="name.errors.required">
                  Name is required.
                </div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" name="email" id="email" [(ngModel)]="model.email" class="form-control"
                placeholder="E-Mail" required email #email="ngModel">
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
                <div *ngIf="email.errors.required">Email is required.</div>
                <div *ngIf="email.errors.email">Email must be a valid email address.</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" name="phone" id="phone" [(ngModel)]="model.phone" class="form-control"
                placeholder="Phone">
            </div>
            <div class="form-group">
              <textarea name="message" id="message" [(ngModel)]="model.message" rows="5" class="form-control"
                placeholder="Message" required #message="ngModel"></textarea>
              <div *ngIf="message.invalid && (message.dirty || message.touched)" class="error">
                <div *ngIf="message.errors.required">Message is required.</div>
              </div>
            </div>
            <div class="form-group">
              <button [disabled]="!contactForm.form.valid" class="btn btn-success">Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="service-error" *ngIf="error">
      <h1>{{error.errorTitle}}</h1>
      <h3>{{error.errorDesc}}</h3>
    </div>
    <div [hidden]="!submitted" class="contact-message">
      <div *ngIf="success.id" class="contact-success">
        <h2 class="success">Success!</h2>
        <h4>Contact form has been successfully submitted.</h4>
        <br />
        <button (click)="gotoHome()" class="btn btn-info">Go to Home</button>
      </div>
    </div>
  </div>
</section>