<form [formGroup]="blogForm" (ngSubmit)="onSubmit()">
    <div class="top-main-wrapper">
        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-6 p-md-6">
                        <h3>{{pageTitle}}</h3>
                    </div>
                    <div class="p-col-6 p-md-6">

                        <button pButton pRipple icon="pi pi-save" label="salva"
                            class="p-button p-button-success p-mr-2 pull-right" [disabled]="!blogForm.valid"></button>

                        <button type="button" pButton pRipple icon="pi pi-chevron-left" label=" indietro"
                            class="p-button p-button-back pull-right  p-mr-2" [routerLink]="['/admin/trustpilot/']"></button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">
                <div class="p-col-12 p-md-12">


                    <div class="p-grid">

                        <div class="p-col-12 p-md-12">
                            <p-card>
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-7">
                                        <div class="left-section-card">
                                            <div class="p-fluid">


                                                <div class="p-field p-grid">
                                                    <label class="p-col-12 p-mb-2">Nome <span
                                                            class="required">*</span></label>
                                                    <div class="p-col-12 p-md-12">
                                                        <input type="text" pInputText placeholder="Nome"
                                                            formControlName="name" />
                                                    </div>

                                                </div>
                                            </div>



                                            <div class="p-fluid">

                                                <div class="p-field p-grid">
                                                    <label class="p-col-12 p-mb-2">Seo Title <span
                                                            class="required">*</span></label>
                                                    <div class="p-col-12 p-md-12">
                                                        <input type="text" pInputText
                                                            placeholder="Seo title" formControlName="seo_title" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="p-fluid">

                                                <div class="p-field p-grid">
                                                    <label class="p-col-12 p-mb-2">Seo Url <span
                                                            class="required">*</span></label>
                                                    <div class="p-col-12 p-md-12">
                                                        <input type="text" pInputText appSlugTransform
                                                            placeholder="Descrizione" formControlName="seo_url" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="p-fluid">
                                                <div class="p-field p-grid">
                                                    <div class="p-col-12 p-mb-2">
                                                        <label class="">Descrizione breve <span
                                                                class="required">*</span></label>
                                                    </div>
                                                    <div class="p-col-12 p-md-12">

                                                        <p-editor formControlName="description"
                                                            [style]="{ height: '220px' }">
                                                            <ng-template pTemplate="header">
                                                                <span class="ql-formats">
                                                                    <button type="button" class="ql-bold"
                                                                        aria-label="Bold"></button>
                                                                    <button type="button" class="ql-italic"
                                                                        aria-label="Italic"></button>
                                                                    <button type="button" class="ql-underline"
                                                                        aria-label="Underline"></button>
                                                                    <button type="button" class="ql-clean"
                                                                        aria-label="clean">
                                                                    </button>

                                                                    <button type="button" class="ql-link"
                                                                        aria-label="Link">
                                                                    </button>
                                                                </span>
                                                            </ng-template>
                                                        </p-editor>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="p-col-12 p-md-5">
                                        <div class="well">

                                            <div class="blog-form">
                                                <div class="p-fluid">
                                                    <div class="p-grid">
                                                        <div class="p-col-12 p-md-4">
                                                            <div class="p-grid">

                                                                <label class="p-col-12 p-mb-2">Attivo <span
                                                                        class="required">*</span></label>
                                                                <div class="p-col-12 p-md-12">
                                                                    <p-selectButton [options]="stateOptions"
                                                                        formControlName="is_active" optionLabel="label"
                                                                        optionValue="value"></p-selectButton>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="p-col-12 p-md-8">
                                                            <div class="p-grid">
                                                                <label class="p-col-12 p-mb-2">In evidenzia <span
                                                                        class="required">*</span></label>
                                                                <div class="p-col-12 p-md-12">
                                                                    <p-dropdown [options]="statePublished"
                                                                        formControlName="featured"
                                                                        [(ngModel)]="selectedStatePublished"
                                                                        optionLabel="label" optionValue="value">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-card>
                        </div>
                    </div>



                    <div class="form-group form-row">
                        <label class="col-md-3"></label>
                        <div class="col-md-9">
                            <input type="hidden" formControlName="id">
                        </div>
                    </div>




                </div>


            </div>
        </div>
    </div>
</form>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1"></p-toast>