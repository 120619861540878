

<app-topnav></app-topnav>

<div class="top-main-wrapper">


    <div class="dash-header">
        <div class="dash-title">
    
            <div class="p-field p-grid">
                <div class="p-col-6 p-md-6">
                    <h3>Pagine</h3>
                </div>
                <div class="p-col-6 p-md-6">
    
                    <button pButton pRipple icon="pi pi-plus" [routerLink]="['/admin/pages/create']" label=" aggiungi" class="p-button p-button-primary -mr-2  pull-right"></button>
                    <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf" labe l="Esporta" (click)="exportPdf()" class="p-button-options p-mr-2 pull-right"  tooltipPosition="bottom"></button> -->
      
                </div>
            </div>
    
        </div>
    </div>
</div>



<div class="main-inner">





<div class="p-fluid">
    <div class="p-field p-grid">
        <div class="p-col-12 p-md-12">

                <div class="filter-options">

                    <div class="p-field p-grid">

                        <div class="p-col-12 p-md-8">
                            <div class="item-size pull-left">
                                <span class="filter-tag" *ngIf="nameFilter" >Nome: {{nameFilter}} <span class="close"><i class="pi pi-times"  (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="descriptionFilter">Cognome: {{descriptionFilter}} <span class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                            
                            </div>
                        </div>
                    </div>
                </div>

                    <div *ngFor=" let pages of pagess  | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: count }; let i = index"></div>

                <p-table #dt [columns]="cols" [value]="pagess" [responsive]="false" [autoLayout]="false"  [paginator]="false" [rows]="100">

                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr *ngIf="rowData">
                            <td>
                                <div>{{ rowData.page_title }}</div>
                            </td>
                       
                            <td>
                                <div>{{ rowData.page_url }}</div>
                            </td>   
                                
                            <td class="action">
                                <button pButton pRipple icon="pi pi-trash" class="p-button p-button-danger pull-right p-mr-2"
                                (click)="onDelete(rowData.id)"></button>

                                <button pButton pRipple icon="pi pi-pencil" class="p-button p-button-success pull-right p-mr-2"
                                [routerLink]="['/admin/pages/edit', rowData.id]"></button>
                            <button pButton pRipple icon="pi pi-eye" class="p-button p-button-primary pull-right p-mr-2"
                                (click)="edit(rowData)"></button>

                    
                                              
                            </td>
                        </tr>

                        <ng-container *ngIf="rowData.subpages !== null">

                        <tr *ngFor="let subpages of rowData.subpages" class="subpages">
                            <td>
                        
                                <p-tag>{{rowData.page_title}}</p-tag> {{ subpages.page_title }}
                            </td>
                       
                            <td>
                                {{ subpages.page_url }}
                            </td> 
                            <td>
                                <button pButton pRipple icon="pi pi-trash" class="p-button p-button-danger pull-right p-mr-2"
                                (click)="onDelete(subpages.id)"></button>

                                <button pButton pRipple icon="pi pi-pencil" class="p-button p-button-success pull-right p-mr-2"
                                [routerLink]="['/admin/pages/edit', subpages.id]"></button>
                            <button pButton pRipple icon="pi pi-eye" class="p-button p-button-primary pull-right p-mr-2"
                                (click)="edit(subpages)"></button>                            </td> 
                        </tr>
                    </ng-container>
                    </ng-template>
                    
                </p-table> 

     
            </div>
        </div>
    </div>
</div>

<p-sidebar [(visible)]="productDialog" position="right"  [style]="{width:'50%'}">
    <ng-template pTemplate="content">
        <div class="p-col-12 p-md-12">

            <h1>{{pages.page_title}}</h1>
          
            <p [innerHTML]="pages.description_1"></p>

            <div class="clearfix"></div><br>
            <button pButton pRipple icon="pi pi-pencil" label="Modifica" class="p-button p-button-primary p-mr-2" [routerLink]="['/admin/pages/edit', pages.id]"></button>
   
   </div>
    </ng-template>
</p-sidebar>




<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No" (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si" (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1"></p-toast>

<p-toast key="myKey2">{{error}}</p-toast>



