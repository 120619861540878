import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { SlideshowService } from "src/app/services/slideshow.service";

@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"]
})
export class UploadComponent implements OnInit {

  @Input() element:  any = [];

  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;
  blogForm: FormGroup;
  imageInfos?: Observable<any>;
  images: any;
  error: string;
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  uploadError: string;
  cols: any[];
  imagePath: any;
  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private slideshowService: SlideshowService,
  ) {}

  public ngOnInit(): void {

    this.imageInfos = this.slideshowService.getImages(this.element.id);

    
  }



  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };

        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
  }

  onDeleteImage(image_id: any, image_name: any, page_id: any) {
    this.confirmationService.confirm({
      message: "Sei sicuro di volerlo cancellare = " + image_name,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.slideshowService.delete_image(+image_id, +page_id).subscribe(
          (res) => {
            console.log("ok");
            this.messageService.add({
              key: "myKeyUpload",
              severity: "success",
              summary: "Attenzione",
              detail: "Cancellazione avvenuto con successo",
            });
            this.imageInfos = this.slideshowService.getImages(this.element.id);
          },
          (error) => (this.error = error)
        );
      },
    });
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      const id = this.route.snapshot.paramMap.get("id");

      this.slideshowService.upload(file, this.element.id).subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            const msg = "Uploaded the file successfully: " + file.name;
            this.message.push(msg);
            this.imageInfos = this.slideshowService.getImages(this.element.id);
            this.previews = [];
            this.progressInfos = [];
            setTimeout(() => {
              this.message = [];
            }, 1500);

          }
        },
        error: (err: any) => {
          this.progressInfos[idx].value = 0;
          const msg = "Could not upload the file: " + file.name;
          this.message.push(msg);
        },
      });
    }
    this.selectedFiles = null;
  }

  uploadFiles(): void {
    this.message = [];

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }



  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.blogForm.get("image").setValue(file);

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imagePath = reader.result;
      };
    }
  }

  removeImageFile() {
    this.imagePath = "";
    console.log(this.myInputVariable.nativeElement.files);
    this.myInputVariable.nativeElement.value = "";
    console.log(this.myInputVariable.nativeElement.files);
  }

  onRowEditSave(image: any) {

    const formData = new FormData();
    formData.append("description", image.description);
    formData.append("image_order", image.image_order);

    const id = image.image_id;

      this.slideshowService.update(formData, +id).subscribe(
        (res) => {

          if (res.status == "error") {
            this.messageService.add({
              key: "myKey1",
              severity: "warn",
              summary: "Informazioni",
              detail: "Errore",
            });

            this.uploadError = res.message;
          } else {

            this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Attenzione', detail: 'Salvato con sucesso' });

            this.imageInfos = this.slideshowService.getImages(this.element.id);

          }
        },
        (error) => (this.error = error)
      );
    
  }

  onRowEditInit(image: any) {
    this.imageInfos[image.image_id] = { ...image };
  }


}
