<p-card>
    <p-toast key="myKeyUpload"></p-toast>


    <div class="p-grid">

        <div class="p-col-12 p-md-8">
            <div class="left-section-card">
                <div class="p-fluid">
                    <div class="blog-form">

                        <p-table #dt [columns]="cols" [value]="imageInfos | async" [responsive]="false"
                            [autoLayout]="false" [globalFilterFields]="['name', 'description']" dataKey="id"
                            editMode="row"
                            currentPageReportTemplate="Visualizza {first} di {last} su {totalRecords} Items"
                            [paginator]="true" [rows]="10" [showCurrentPageReport]="true">

                            <ng-template pTemplate="header">
                                <tr>


                                    <th width="70px"></th>

                                    <th>Nome Documento</th>
                                    <th></th>
                                    <th></th>

                                </tr>

                            </ng-template>
                            <ng-template pTemplate="body" let-image let-editing="editing" let-columns="columns">
                                <tr [pEditableRow]="image">
                                    <td>
                                        <a [href]="image.download_path" target="_blank"
                                            class="p-button p-button-primary p-mr-2"><i class="pi pi-download"></i></a>

                                    </td>

                                    <td> {{image.file_name}}
                                    </td>

                                    <td pEditableColumn>

                                        <p-cellEditor>
                                            <ng-template pTemplate="input">

                                                <div class="p-inputgroup">
                                                    <span class="p-inputgroup-addon" (click)="onRowEditSave(image)">
                                                        <i class="pi pi-save"></i>
                                                    </span>
                                                    <input pInputText type="text" [(ngModel)]="image.description">

                                                </div>

                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <div class="p-inputgroup">
                                                    <span class="p-inputgroup-addon">
                                                        <i class="pi pi-pencil"></i>
                                                    </span>
                                                    <input pInputText [(ngModel)]="image.description" />
                                                </div>



                                            </ng-template>
                                        </p-cellEditor>

                                    </td>

                                    <td class="action">
                                        <button pButton pRipple icon="pi pi-trash"
                                            class="p-button p-button-danger p-mr-2"
                                            (click)="onDeleteFile(this.image.image_id, this.image.image_name, this.image.page_id)"></button>

                                    </td>
                                </tr>


                            </ng-template>
                        </p-table>

                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-4">
            <div class="well">


                <div *ngFor="let progressInfo of progressInfos" class="mb-2">
                    <span>{{ progressInfo.fileName }}</span>
                    <p-progressBar [value]="progressInfo.value"></p-progressBar>

                </div>



                <div class="row">
                    <div class="col-8">
                        <div class="button_outer_big">
                            <div class="btn_upload">
                                <input type="file" accept="application/pdf" (change)="selectFiles($event)" />
                                Carica File (Max. 5MB)
                            </div>
                        </div>
                    </div>

                    <div class="col-4">
                        <button pButton class="p-button p-button-success p-mr-2" [disabled]="!selectedFiles"
                            (click)="uploadFiles()">
                            Upload
                        </button>
                        <br><br>
                    </div>
                </div>

                <div *ngIf="message.length" class="alert alert-secondary my-3" role="alert">
                    <div *ngFor="let msg of message; let i = index" class="p-message p-message-success">
                        <div class="p-message-wrapper">
                            <span class="p-message-summary">{{ msg }}</span>

                        </div>
                    </div>
                </div>

                <div *ngFor="let preview of previews">
                    <div class="p-grid">
                        <div class="p-col-8">
                            {{preview.name}} </div>
                        <div class="p-col-4">

                            <p-tag class="pull-right">{{preview.size | filesize}}</p-tag>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>

</p-card>