<section class="login-wrap mtb-40">
  <div class="login-box" *ngIf="!error">

    <div class="login-box-inner">
      <div class="p-grid">
        <div class="p-col-12">

          <div class="logo-wrapper" routerLink="/admin">
            <h3>NEXO<span>.</span></h3>

          </div>
          <p class="text-center">Recupera Password</p>

        </div>
      </div>

      <div class="p-grid" *ngIf="loginError">
        <div class="p-col-12">

          <div class="alert alert-danger">
            {{loginError}}
          </div>
        </div>
      </div>


          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="p-fluid">
              <div class="p-field">
                <input type="text" pInputText formControlName="user_email" class="form-control" placeholder="Email"
                  required>
                <div *ngIf="user_email.invalid && (user_email.dirty || user_email.touched)"
                  class="error alert alert-danger">
                  <div *ngIf="user_email.errors">
                    Email is required.
                  </div>
                </div>
              </div>

              <div class="p-field">
                <button pButton pRipple label="Recover Password" class="p-button p-button-primary btn-block"
                  [disabled]="!loginForm.valid"></button>
              </div>
            </div>
          </form>
          <div class="p-field text-center">
            <a routerLink="/login">Login</a>
          </div>
          <div class="service-error" *ngIf="error">
            <h1>{{error.errorTitle}}</h1>
            <h3>{{error.errorDesc}}</h3>
          </div>
    </div>
  </div>
</section>