import { Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpParams,
  HttpResponse,
  HttpRequest,
} from "@angular/common/http";
import { CrudOperations } from "./crud-operations.interface";
import { catchError, map } from "rxjs/operators";
import { Billings } from "../models/billings";
import { Task } from "../models/tasks";
import { Orders } from "../models/orders";
import { Quotes } from "../models/quotes";
import { environment } from "src/environments/environment";

export abstract class CrudService<T, ID> implements CrudOperations<T, ID> {
  currentUser: any;
  submitted = false;
  returnUrl: string;
  error: {};
  loginError: string;
  username: string;
  user_id: number;
  size: number;

  password: string;
  first_name: string;
  last_name: string;
  pages: any[];

  constructor(protected _http: HttpClient, protected _base: string) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");
  }

  getParams(params: HttpParams, pars: any): HttpParams {
    if (pars.name) {
      params = params.append("name", pars.name);
    }
    if (pars.description) {
      params = params.append("description", pars.description);
    }
    if (pars.email) {
      params = params.append("email", pars.email);
    }

    if (pars.user_email) {
      params = params.append("user_email", pars.user_email);
    }

    if (pars.account_type) {
      params = params.append("account_type", pars.account_type);
    }

    if (pars.like_from_ip) {
      params = params.append("like_from_ip", pars.like_from_ip);
    }

    if (pars.user_id) {
      params = params.append("user_id", pars.user_id);
    }

    if (pars.category_description) {
      params = params.append("category_description", pars.category_description);
    }

    if (pars.category_name) {
      params = params.append("category_name", pars.category_name);
    }

    if (pars.code) {
      params = params.append("code", pars.code);
    }

    if (pars.skills) {
      params = params.append("skills", pars.skills);
    }

    if (pars.field) {
      params = params.append("field", pars.field);
    }

    if (pars.tour_id) {
      params = params.append("tour_id", pars.tour_id);
    }
    if (pars.code_int) {
      params = params.append("code_int", pars.code_int);
    }
    if (pars.brand) {
      params = params.append("brand", pars.brand);
    }

    if (pars.title) {
      params = params.append("title", pars.title);
    }

    if (pars.startup_description) {
      params = params.append("startup_description", pars.startup_description);
    }

    if (pars.startup_name) {
      params = params.append("startup_name", pars.startup_name);
    }

    if (pars.page_title) {
      params = params.append("page_title", pars.page_title);
    }

    if (pars.level) {
      params = params.append("level", pars.level);
    }

    if (pars.status) {
      params = params.append("status", pars.status);
    }

    if (pars.startup_id) {
      params = params.append("startup_id", pars.startup_id);
    }

    if (pars._sort_field) {
      params = params.append("_sort_field", pars._sort_field);
    }

    if (pars._sort_order) {
      params = params.append("_sort_order", pars._sort_order);
    }

    if (pars.id) {
      params = params.append("id", pars.id);
    }
    if (pars.dateFrom) {
      params = params.append("date_from", pars.dateFrom);
    }
    if (pars.client) {
      params = params.append("client", pars.client);
    }
    if (pars.employee) {
      params = params.append("employee", pars.employee);
    }
    if (pars.project_id) {
      params = params.append("project_id", pars.project_id);
    }
    if (pars.type) {
      params = params.append("type", pars.type);
    }
    if (pars.dateTo) {
      params = params.append("date_to", pars.dateTo);
    }
    params = params.append("_start", pars.page);
    if (pars.size) {
      params = params.append("_limit", pars.size);
    }

    if (pars.is_featured) {
      params = params.append("is_featured", pars.is_featured);
    }

    return params;
  }

  public find(id: ID, pars: any): Observable<T[]> {
    let params = new HttpParams();
    params = this.getParams(params, pars);
    return this._http
      .get<HttpResponse<T[]>>(this._base + "/find/" + id, {
        observe: "response",
        params,
      })
      .pipe(
        map((res) => {
          const totalCountHeader = res.headers.get("x-total-count");
          this.size = totalCountHeader !== null ? +totalCountHeader : 0;
          const ts: any = res.body;
          return ts;
        }),
        catchError(this.handleError)
      );
  }

  public getAllList(): Observable<T[]> {
    const userId = this.currentUser.user_id;
    return this._http
      .get<HttpResponse<T[]>>(this._base + "/list/" + userId, {
        observe: "response",
      })
      .pipe(
        map((res) => {
          this.size =
            res.headers.get("x-total-count") != null
              ? +res.headers.get("x-total-count")
              : 0;
          const ts: any = res.body;
          return ts;
        }),
        catchError(this.handleError)
      );
  }

  public getList(pars: any): Observable<T[]> {
    let params = new HttpParams();
    const userId = this.currentUser.user_id;

    params = this.getParams(params, pars);
    return this._http
      .get<HttpResponse<T[]>>(this._base + "/list/" + userId, {
        observe: "response",
        params,
      })
      .pipe(
        map((res) => {
          this.size =
            res.headers.get("x-total-count") != null
              ? +res.headers.get("x-total-count")
              : 0;
          const ts: any = res.body;
          return ts;
        }),
        catchError(this.handleError)
      );
  }

  public list(pars: any): Observable<T[]> {
    let params = new HttpParams();

    params = this.getParams(params, pars);
    return this._http
      .get<HttpResponse<T[]>>(this._base + "/", {
        observe: "response",
        params,
      })
      .pipe(
        map((res) => {
          this.size =
            res.headers.get("x-total-count") != null
              ? +res.headers.get("x-total-count")
              : 0;
          const ts: any = res.body;
          return ts;
        }),
        catchError(this.handleError)
      );
  }

  public getAll(pars: any): Observable<T[]> {
    let params = new HttpParams();
    const userId = this.currentUser.user_id;

    params = this.getParams(params, pars);
    return this._http
      .get<HttpResponse<T[]>>(this._base + "/list_all/" + userId, {
        observe: "response",
        params,
      })
      .pipe(
        map((res) => {
          this.size =
            res.headers.get("x-total-count") != null
              ? +res.headers.get("x-total-count")
              : 0;
          const ts: any = res.body;
          return ts;
        }),
        catchError(this.handleError)
      );
  }

  public getAllListDocuments(pars: any, id: ID): Observable<T[]> {
    let params = new HttpParams();
    const userId = this.currentUser.user_id;
    params = this.getParams(params, pars);
    return this._http
      .get<HttpResponse<T[]>>(this._base + "/list_user/" + id + "/" + userId, {
        observe: "response",
        params,
      })
      .pipe(
        map((res) => {
          this.size =
            res.headers.get("x-total-count") != null
              ? +res.headers.get("x-total-count")
              : 0;
          const ts: any = res.body;
          return ts;
        }),
        catchError(this.handleError)
      );
  }

  public find_documents(pars: any, id: ID): Observable<T[]> {
    let params = new HttpParams();
    const userId = this.currentUser.user_id;
    params = this.getParams(params, pars);
    return this._http
      .get<HttpResponse<T[]>>(
        this._base + "/timesheets_by_employee/" + id + "/" + userId,
        {
          observe: "response",
          params,
        }
      )
      .pipe(
        map((res) => {
          this.size =
            res.headers.get("x-total-count") != null
              ? +res.headers.get("x-total-count")
              : 0;
          const ts: any = res.body;
          return ts;
        }),
        catchError(this.handleError)
      );
  }

  count() {
    const userId = this.currentUser.user_id;
    return this._http
      .get<T>(this._base + "/count/" + userId)
      .pipe(catchError(this.handleError));
  }

  countCharts() {
    const userId = this.currentUser.user_id;
    return this._http
      .get<T>(this._base + "/user/" + userId)
      .pipe(catchError(this.handleError));
  }

  send(emailData: any) {
    return this._http.post(this._base + "/send/", emailData, {
      headers: { "Content-Type": "application/json" },
    });
  }

  sendNotes(emailData: any) {
    return this._http.post(this._base + "/send_notes/", emailData, {
      headers: { "Content-Type": "application/json" },
    });
  }

  send_review(emailData: any) {
    return this._http.post(this._base + "/send_review/", emailData, {
      headers: { "Content-Type": "application/json" },
    });
  }

  countChartsNone() {
    const userId = this.currentUser.user_id;
    return this._http
      .get<T>(this._base + "/user_none/" + userId)
      .pipe(catchError(this.handleError));
  }

  getToday(userId: number) {
    return this._http
      .get<T>(this._base + "/today/" + userId)
      .pipe(catchError(this.handleError));
  }

  getActive(userId: number) {
    return this._http
      .get<T>(this._base + "/active/" + userId)
      .pipe(catchError(this.handleError));
  }

  skills(id: ID) {
    const userId = this.currentUser.user_id;
    return this._http
      .get<T>(this._base + "/skills/" + id + "/" + userId)
      .pipe(catchError(this.handleError));
  }

  dates(id: ID) {
    const userId = this.currentUser.user_id;
    return this._http
      .get<T>(this._base + "/dates/" + id + "/" + userId)
      .pipe(catchError(this.handleError));
  }

  countTotal(id: ID) {
    const userId = this.currentUser.user_id;
    return this._http
      .get<T>(this._base + "/count_total/" + userId)
      .pipe(catchError(this.handleError));
  }

  get_chart() {
    const userId = this.currentUser.user_id;
    return this._http
      .get<T>(this._base + "/get_chart/" + userId)
      .pipe(catchError(this.handleError));
  }

  countTotalNotPaid(id: ID) {
    const userId = this.currentUser.user_id;
    return this._http
      .get<T>(this._base + "/count_total_no_paid/" + userId)
      .pipe(catchError(this.handleError));
  }

  getId(id: ID) {
    const userId = this.currentUser.user_id;
    return this._http
      .get<T>(this._base + "/id/" + id)
      .pipe(catchError(this.handleError));
  }

  getImages(id: ID): Observable<any> {
    const userId = this.currentUser.user_id;
    return this._http.get<T>(this._base + "/getimages/" + id + "/" + userId);
  }

  getImage(id: ID): Observable<any> {
    const userId = this.currentUser.user_id;
    return this._http.get<T>(this._base + "/getimage/" + id + "/" + userId);
  }

  getFiles(id: ID): Observable<any> {
    const userId = this.currentUser.user_id;
    return this._http.get<T>(this._base + "/getfiles/" + id + "/" + userId);
  }

  upload(file: File, id): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    const userId = this.currentUser.user_id;

    formData.append("id", id);
    formData.append("image", file);

    const req = new HttpRequest(
      "POST",
      `${this._base}/image_upload/` + id + "/",
      formData,
      {
        reportProgress: true,
        responseType: "json",
      }
    );

    return this._http.request(req);
  }

  create(blog) {
    const userId = this.currentUser.user_id;
    return this._http
      .post<any>(this._base + "/create/" + userId, blog)
      .pipe(catchError(this.handleError));
  }

  update(blog, id: number) {
    const userId = this.currentUser.user_id;
    return this._http
      .post<any>(this._base + "/update/" + id, blog)
      .pipe(catchError(this.handleError));
  }

  updateStatus(blog, id: number) {
    const userId = this.currentUser.user_id;
    return this._http
      .post<any>(this._base + "/status/" + id + "/" + userId, blog)
      .pipe(catchError(this.handleError));
  }

  activeAccountEmail(id: ID) {
    return this._http
      .get<T>(this._base + "/activate_account/" + id)
      .pipe(catchError(this.handleError));
  }

  updateDates(blog, id: number) {
    const userId = this.currentUser.user_id;
    return this._http
      .post<any>(this._base + "/update_dates/" + id + "/" + userId, blog)
      .pipe(catchError(this.handleError));
  }

  update_skills(blog, id: number) {
    const userId = this.currentUser.user_id;
    return this._http
      .post<any>(this._base + "/update_skills/" + id + "/" + userId, blog)
      .pipe(catchError(this.handleError));
  }

  update_priority(blog, id: number) {
    const userId = this.currentUser.user_id;
    return this._http
      .post<any>(this._base + "/update_priority/" + id + "/" + userId, blog)
      .pipe(catchError(this.handleError));
  }

  update_quantity(blog, id: number) {
    const userId = this.currentUser.user_id;
    return this._http
      .post<any>(this._base + "/update_quantity/" + id + "/" + userId, blog)
      .pipe(catchError(this.handleError));
  }

  delete(id: number) {
    const userId = this.currentUser.user_id;
    return this._http
      .delete(this._base + "/delete/" + id + "/" + userId)
      .pipe(catchError(this.handleError));
  }

  delete_image(image_id: number, page_id: number) {
    const userId = this.currentUser.user_id;
    return this._http
      .delete(
        this._base + "/delete_image/" + image_id + "/" + page_id + "/" + userId
      )
      .pipe(catchError(this.handleError));
  }

  delete_file(image_id: number, page_id: number) {
    const userId = this.currentUser.user_id;
    return this._http
      .delete(
        this._base + "/delete_file/" + image_id + "/" + page_id + "/" + userId
      )
      .pipe(catchError(this.handleError));
  }

  save(t: T): Observable<T> {
    return this._http.post<T>(this._base, t);
  }

  update_new(id: ID, t: T): Observable<T> {
    return this._http.put<T>(this._base + "/" + id, t, {});
  }

  findOne_new(id: ID): Observable<T> {
    return this._http.get<T>(this._base + "/" + id);
  }

  findAll_new(): Observable<T[]> {
    return this._http.get<T[]>(this._base);
  }

  delete_new(id: ID): Observable<T> {
    return this._http.delete<T>(this._base + "/" + id);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened. Please try again later.");
  }
}
