import { Component, OnInit, ViewChild } from '@angular/core';
import { Clients } from '../../../models/clients';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { CategoryService } from '../../../services/categories.service';
import { Category } from '../../../models/category';
import { MessageService } from 'primeng/api';
import { ComuniService } from 'src/app/services/comuni.service';
import { Comuni } from 'src/app/models/comuni';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { NgxSpinnerService } from "ngx-spinner";
import { PARAM_CLIENTS_PATH, PARAM_EMPLOYEES_PATH, PARAM_USERS_PATH } from '../../constants/constants';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html'
})
export class ManageUsersComponent implements OnInit {
  clients: any = [];
  filterSidebar: boolean;
  client: Clients;
  categories: any = [];
  category: Category;
  error: string;
  comuni: any = [];
  cols: any[];
  exportColumns: any[];
  _selectedColumns: any[];
  loading: boolean;
  totalRecords: string;
  currentUser: any ;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  public base_path: string;
  basePath: string;
  pageOfItems: Array<any>;
  searchWrapper: boolean = false;
  nameFilter: string;
  descriptionFilter: string;
  codeFilter: string;
  idFilter: string;
  accountTypeFilter: string;
  emailFilter: string;
  codeIntFilter: string;
  brandFilter: string;
  private category_id: number;
  private id: number;

  productDialog: boolean = false;


  showDialog() {
    this.productDialog = true;
  }


  trackByFn(index, item) {
    return item.id;
  }


  constructor(
    private usersService: UsersService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private comuniService: ComuniService,
    private categoryService: CategoryService,
    private confirmationService: ConfirmationService,) {
      const doc = new jsPDF();

  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '[]');
    const userId = this.currentUser.user_id;

   

    this.spinner.show();
      this.load();
      
      this.cols = [
        { field: "id", header: "ID" },

        { field: "user_email", header: "Email" },
        { field: "is_active", header: "Attivo" },


  
      ];
      this._selectedColumns = this.cols;
      this.exportColumns = this.cols.map(col => ({
        title: col.header,
        dataKey: col.field
      }));
      this.spinner.hide();


  }



  getRequestParams(searchTitle, emailTitle, idTitle, accountTypeTitle, page, pageSize): any {
    // tslint:disable-next-line:prefer-const
    let path = PARAM_USERS_PATH;
    const params = {};
    let adder = '?';
    if (page) {
      params[`page`] = page - 1;
      path += adder + 'page=' + (page - 1);
      adder = '&';
    }
    if (searchTitle) {
      params[`display_name`] = searchTitle;
      path += adder + 'display_name=' + searchTitle;
      adder = '&';
    }
    if (emailTitle) {
      params[`user_email`] = emailTitle;
      path += adder + 'user_email=' + emailTitle;
      adder = '&';
    }

    if (idTitle) {
      params[`id`] = idTitle;
      path += adder + 'id=' + idTitle;
      adder = '&';
    }

    if (accountTypeTitle) {
      params[`account_type`] = accountTypeTitle;
      path += adder + 'account_type=' + accountTypeTitle;
      adder = '&';
    }

    if (pageSize) {
      params[`size`] = pageSize;
      path += adder + 'size=' + pageSize;
    }
    window.history.replaceState({}, '', path);

    return params;

  }

  reset(): void {
    this.nameFilter = '';
    this.emailFilter = '';
    this.idFilter = '';
    this.load();

  }

  load(): void {
    const params = this.getRequestParams(
      this.nameFilter,
      this.emailFilter,
      this.idFilter,
      this.accountTypeFilter,
      this.page,
      this.pageSize
    );
    this.usersService.list(params).subscribe((pData) => {
      this.clients = pData;
      this.count = this.usersService.size;
    });
  }



  public handlePageChange(event): void {
    this.page = event;
    this.load();

  }

  public selectionItemForFilter(e) {
    const colsTempor = e.value;
    colsTempor.sort(function (a, b) {
      return a.index - b.index;
    });
    this.cols = [];
    this.cols = colsTempor;
    if (e.value.length > 10) {
      e.value.pop();
    }
  }


  getCategoryItem(category_id: string, id: string) {
    return this.clients.find(item => item.id === category_id);
  }


  edit(client: Clients) {
    this.client = { ...client };
    this.productDialog = true;
  }


  hideDialog() {
    this.productDialog = false;
  }

  exportPdf() {
    // const doc = new jsPDF();
    const doc = new jsPDF('l','pt','A4');
    doc['autoTable'](this.exportColumns, this.clients);
    // doc.autoTable(this.exportColumns, this.products);
    doc.save("clients.pdf");
  }




  onDelete(id: number, title: string) {

    this.confirmationService.confirm({
      message: 'Sei sicuro di volerlo cancellare',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.usersService.delete(+id).subscribe(
          res => {
            console.log(res);
            this.ngOnInit();
            this.messageService.add({ key: 'myKey1', severity: 'success', summary: 'Attenzione', detail: 'Cancellazione avvenuto con successo' });

          },
          error => {
            this.error = error;
            this.messageService.add({ key: 'myKey2', severity: 'warn', summary: 'Attenzione', detail: 'Errore backend' });
          });
      },

    });


  }

}
