<app-topnav></app-topnav>

<form [formGroup]="blogForm">

    <div class="top-main-wrapper">

        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-8 p-md-8">
                        <h3>Review</h3>

                        
                    </div>
                    <div class="p-col-4 p-md-4">
                        <button pButton pRipple icon="pi pi-save" label="salva"
                            class="p-button pull-right p-button-success p-mr-2" (click)="onSubmit()"
                            [disabled]="!blogForm.valid"></button>


                        <button type="button" pButton pRipple icon="pi pi-chevron-left" label=" indietro"
                            class="p-button p-button-back pull-right  p-mr-2" [routerLink]="['/admin/reviews/']"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">




                <div class="p-col-12 p-md-12">


                    <p-tabView>
      

                        <p-tabPanel header="Informazioni">
                            <div class="p-grid">

                                <div class="p-col-12 p-md-8">
                                    <p-card>


                                        <div class="p-fluid">

                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Rating<span
                                                        class="required"></span></label>
                                                <div class="p-col-12">

                                            <p-rating
                                            formControlName="value1"
                                            [cancel]="false"
                                            [readonly]="true"
                                          ></p-rating>
                                                </div>
                                            </div>

                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Startup<span
                                                        class="required"></span></label>
                                                <div class="p-col-12">
        
                                                    <input type="text" [style]="{ width: '100%' }" pInputText
                                                        formControlName="startup_id">
        
                                                    <!-- <input type="text" [style]="{ width: '100%' }" [(ngModel)]="data" value="{{rows.value | json}}" pInputText formControlName="data" >-->
        
                                                </div>
                                            </div>

                                            <div class="p-field p-grid">
                                                <label class="p-col-12">User<span
                                                        class="required"></span></label>
                                                <div class="p-col-12">
        
                                                    <input type="text" [style]="{ width: '100%' }" pInputText
                                                        formControlName="user_id">
        
                                                    <!-- <input type="text" [style]="{ width: '100%' }" [(ngModel)]="data" value="{{rows.value | json}}" pInputText formControlName="data" >-->
        
                                                </div>
                                            </div>

                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Description<span
                                                        class="required"></span></label>
                                                <div class="p-col-12">
        
                                                    <textarea pInputTextarea type="text" [style]="{ width: '100%' }" 
                                                        formControlName="description"></textarea>
        
                                                    <!-- <input type="text" [style]="{ width: '100%' }" [(ngModel)]="data" value="{{rows.value | json}}" pInputText formControlName="data" >-->
        
                                                </div>
                                        </div></div>
        
                                       

        
        
        
                                        <div class="form-group form-row">
                                            <label class="p-md-3"></label>
                                            <div class="p-md-9">
                                                <input type="hidden" formControlName="id">
                                                <input type="hidden" formControlName="user_id">
        
                                            </div>
                                        </div>

                                    </p-card>
                                </div>


                             
                                <div class="p-col-12 p-md-4" *ngIf="element">
                                    <p-card>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-12 p-mb-3">

                                        <ng-container *ngIf="user">
                                            <button pButton pRipple label="view" icon="pi pi-eye" class="p-button p-button-outline pull-right"
                                            [routerLink]="['/admin/users/edit', user.id]"></button>

                                            <h4 class="p-pb-2 p-mb-0">{{user.display_name}}</h4>
                                           <p class="small">{{user.user_email}}</p> 
                                        </ng-container>
                                            </div>
                                    </div>

                                           <p-tag *ngIf="user" class="cp p-mr-2" severity="default">ID {{user.id}}</p-tag>

                                        <p-tag *ngIf="startupStatus == 1" class="cp" severity="success"
                                        (click)="unpublished(id)">published</p-tag>
        
                                        <p-tag *ngIf="startupStatus == 0" class="cp" severity="danger"
                                        (click)="published(id)">unpublished</p-tag>
                                    </p-card>
                                    <p-card>

                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-12 p-mb-3">

                                        <ng-container *ngIf="user">
                                            <button pButton pRipple label="view" icon="pi pi-eye" class="p-button p-button-outline pull-right"
                                            [routerLink]="['/admin/startups/edit', startup.id]"></button>

                                            <h4 class="p-pb-2 p-mb-0">{{startup.startup_name}}</h4>
                                           <p class="small">{{startup.startup_email}}</p> 
                                        </ng-container>
                                            </div>
                                    </div>

                                    <p-tag *ngIf="startup.status == 1" class="cp" severity="success">published</p-tag>
    
                                    <p-tag *ngIf="startup.status == 0" class="cp" severity="danger">unpublished</p-tag>
                                     
                                    </p-card>
                                </div>
                            </div>
                        </p-tabPanel>
             

                       

                    </p-tabView>



                </div>



            </div>

        </div>
    </div>
</form>




<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1" position="bottom-right"></p-toast>