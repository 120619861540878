import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { AdminComponent } from './admin/admin.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { SettingsComponent } from './settings/settings.component';
import { ManageStartupsComponent } from './startups/manage-startups/manage-startups.component';
import { StartupsFormComponent } from './startups/startups-form/startups-form.component';
import { ManageNewsComponent } from './news/manage-news/manage-news.component';
import { NewsFormComponent } from './news/news-form/news-form.component';
import { ManagePagesComponent } from './pages/manage-pages/manage-pages.component';
import { PagesFormComponent } from './pages/pages-form/pages-form.component';
import { ManageUsersComponent } from './users/manage-users/manage-users.component';
import { UsersFormComponent } from './users/users-form/users-form.component';
import { ManageCategoriesComponent } from './settings/categories/manage-categories/manage-categories.component';
import { CategoriesFormComponent } from './settings/categories/categories-form/categories-form.component';
import { ManageTagsComponent } from './settings/tags/manage-tags/manage-tags.component';
import { TagsFormComponent } from './settings/tags/tags-form/tags-form.component';
import { ManageReviewsComponent } from './reviews/manage-reviews/manage-reviews.component';
import { ReviewsFormComponent } from './reviews/reviews-form/reviews-form.component';
import { ManageTrustpilotComponent } from './settings/trustpilot/manage-trustpilot/manage-trustpilot.component';
import { TrustpilotFormComponent } from './settings/trustpilot/trustpilot-form/trustpilot-form.component';
import { ManageLikesComponent } from './likes/manage-likes/manage-likes.component';
import { LikesFormComponent } from './likes/likes-form/likes-form.component';
import { ManageTechnologyComponent } from './settings/technology/manage-technology/manage-technology.component';
import { TechnologyFormComponent } from './settings/technology/technology-form/technology-form.component';

const routes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        children: [


          { path: "settings", component: SettingsComponent },

         
          { path: "settings/categories", component: ManageCategoriesComponent },
          { path: "settings/categories/edit/:id", component: CategoriesFormComponent },
          { path: "settings/categories/create", component: CategoriesFormComponent },

          { path: "settings/tags", component: ManageTagsComponent },
          { path: "settings/tags/edit/:id", component: TagsFormComponent },
          { path: "settings/tags/create", component: TagsFormComponent },

          { path: "settings/technology", component: ManageTechnologyComponent },
          { path: "settings/technology/edit/:id", component: TechnologyFormComponent },
          { path: "settings/technology/create", component: TechnologyFormComponent },

          { path: "reviews", component: ManageReviewsComponent },
          { path: "reviews/create", component: ReviewsFormComponent },
          { path: "reviews/edit/:id",component: ReviewsFormComponent},

          { path: "likes", component: ManageLikesComponent },
          { path: "likes/create", component: LikesFormComponent },
          { path: "likes/edit/:id",component: LikesFormComponent},

          { path: "startups", component: ManageStartupsComponent },
          { path: "startups/create", component: StartupsFormComponent },
          { path: "startups/edit/:id",component: StartupsFormComponent},

          { path: "news", component: ManageNewsComponent },
          { path: "news/create", component: NewsFormComponent },
          { path: "news/edit/:id",component: NewsFormComponent},

          { path: "pages", component: ManagePagesComponent },
          { path: "pages/create", component: PagesFormComponent },
          { path: "pages/edit/:id",component: PagesFormComponent},


          { path: "trustpilot", component: ManageTrustpilotComponent },
          { path: "trustpilot/create", component: TrustpilotFormComponent },
          { path: "trustpilot/edit/:id",component: TrustpilotFormComponent},
          



          { path: "users", component: ManageUsersComponent },
          { path: "users/create", component: UsersFormComponent },
          { path: "users/edit/:id",component: UsersFormComponent},


          { path: "", component: AdminDashboardComponent },

        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
