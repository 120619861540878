import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ChipsModule } from "primeng/chips";
import { AdminRoutingModule } from './admin-routing.module';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { NgxSpinnerService } from "ngx-spinner";
import {InputNumberModule} from 'primeng/inputnumber';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {TabViewModule} from 'primeng/tabview';
import { DateValidPipe } from "./pipe/date-valid.pipe";
import { JoinPipe } from "./pipe/join.pipe";
import { LimitPipe } from "./pipe/limit.pipe";
import { KeysPipe } from "./pipe/key.pipe";
import {InputTextareaModule} from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import {SelectButtonModule} from 'primeng/selectbutton';
import { ImageModule } from 'primeng/image';

import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { CalendarModule } from 'primeng/calendar';
import { MomentPipe } from './pipe/moment.pipe';
import { InputMaskModule } from 'primeng/inputmask';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import * as $ from 'jquery';
import {TimelineModule} from 'primeng/timeline';
import { TagModule } from 'primeng/tag';
import { NgxSpinnerModule } from "ngx-spinner";
import {PanelModule} from 'primeng/panel';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {KnobModule} from 'primeng/knob';
import { SettingsComponent } from './settings/settings.component';
import { SumPipeModule } from './pipe/sum.pipe';
import { ShortNumberPipe } from './pipe/short-number.pipe';
import {SplitButtonModule} from 'primeng/splitbutton';
import {PanelMenuModule} from 'primeng/panelmenu';
import { SplitCommaStringPipe } from './pipe/split.pipe';
import { TotalPipe } from './pipe/total.pipe';
import { BusyConfig, NgBusyModule } from 'ng-busy';
import {ProgressBarModule} from 'primeng/progressbar';
import { NgxPaginationModule } from 'ngx-pagination';
import {TreeDragDropService} from 'primeng/api';
import {TabMenuModule} from 'primeng/tabmenu';
import { TopNavComponent } from '../top-nav/top-nav.component';
import { ManageStartupsComponent } from './startups/manage-startups/manage-startups.component';
import { StartupsFormComponent } from './startups/startups-form/startups-form.component';
import { NewsFormComponent } from './news/news-form/news-form.component';
import { ManageNewsComponent } from './news/manage-news/manage-news.component';
import { ManagePagesComponent } from './pages/manage-pages/manage-pages.component';
import { PagesFormComponent } from './pages/pages-form/pages-form.component';
import {TagsFormComponent } from './settings/tags/tags-form/tags-form.component';
import { ManageTagsComponent } from './settings/tags/manage-tags/manage-tags.component';
import { FileSaverModule } from 'ngx-filesaver';
import { IImageLoaderOptions, NgxProgressiveImageLoaderModule } from 'ngx-progressive-image-loader';
import { UploadComponent } from './components/upload/upload.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { ManageUsersComponent } from './users/manage-users/manage-users.component';
import { UsersFormComponent } from './users/users-form/users-form.component';
import { SlugTransformDirective } from './pipe/slug-transform.directive';
import { FileSizePipe } from './pipe/filesize.pipe';
import { NgOptimizedImage } from '@angular/common'
import { UploadSingleComponent } from './components/upload-single/upload-single.component';
import {CarouselModule} from 'primeng/carousel';
import { CategoriesFormComponent } from './settings/categories/categories-form/categories-form.component';
import { ManageCategoriesComponent } from './settings/categories/manage-categories/manage-categories.component';
import { ReviewsFormComponent } from './reviews/reviews-form/reviews-form.component';
import { ManageReviewsComponent } from './reviews/manage-reviews/manage-reviews.component';
import { ManageTrustpilotComponent } from './settings/trustpilot/manage-trustpilot/manage-trustpilot.component';
import { TrustpilotFormComponent } from './settings/trustpilot/trustpilot-form/trustpilot-form.component';
import { RatingModule } from 'primeng/rating';
import { ManageLikesComponent } from './likes/manage-likes/manage-likes.component';
import { LikesFormComponent } from './likes/likes-form/likes-form.component';
import { UploadNewsComponent } from './components/upload-news/upload-news.component';
import { TechnologyFormComponent } from './settings/technology/technology-form/technology-form.component';
import { ManageTechnologyComponent } from './settings/technology/manage-technology/manage-technology.component';


@NgModule({
  declarations: [
    ManageUsersComponent,
    UsersFormComponent,
    StartupsFormComponent,
    ManageLikesComponent,
    LikesFormComponent,
    CategoriesFormComponent,
    ManageCategoriesComponent,
    ManageStartupsComponent,
    AdminDashboardComponent,
    ManagePagesComponent,
    PagesFormComponent,
    AdminComponent,
    NewsFormComponent,
    ManageNewsComponent,
    MomentPipe,
    TopNavComponent,
    DateValidPipe,
    JoinPipe,
    KeysPipe,
    ShortNumberPipe,
    UploadFilesComponent,
    LimitPipe,
    TotalPipe,
    ManageTrustpilotComponent,
    FileSizePipe,
    TrustpilotFormComponent,
    TechnologyFormComponent,
    ManageTechnologyComponent,
    UploadSingleComponent,
    TagsFormComponent,
    ManageTagsComponent,
    UploadComponent,
    ReviewsFormComponent,
    UploadNewsComponent,
    ManageReviewsComponent,
    SplitCommaStringPipe,
    SettingsComponent,
    SlugTransformDirective,
    ],
    exports: [
      LimitPipe,
      KeysPipe,
      FileSizePipe,
      ShortNumberPipe,
      TotalPipe,
      SlugTransformDirective,
      DragDropModule,
    ],
  imports: [
    CommonModule,
    RatingModule,
    TableModule,
    MessagesModule,
    SumPipeModule,
    CarouselModule,
    TreeModule,
    TooltipModule,
    NgOptimizedImage,
    InputNumberModule,
    SelectButtonModule,
    ProgressBarModule,
    NgxSpinnerModule,
    KnobModule,
    TabMenuModule,
    InputTextareaModule,
    NgxPaginationModule,
    ImageModule,
    DragDropModule,
    SplitButtonModule,
    ToastModule,
    TabViewModule,
    FileSaverModule,
    OverlayPanelModule,
    MessageModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    PanelModule,
    CheckboxModule,
    EditorModule,
    InputMaskModule,
    CardModule,
    PanelMenuModule,
    ChipsModule,
    TagModule,
    ConfirmDialogModule,
    TimelineModule,
    InputTextModule,
    AdminRoutingModule,
    DialogModule,
    RadioButtonModule,
    CalendarModule,
    DropdownModule,
    HttpClientModule,
    FileUploadModule,
    MultiSelectModule,
    ButtonModule,
    InputSwitchModule,
    AutoCompleteModule,
    SidebarModule,
    NgBusyModule,
    NgOptimizedImage,
    NgxProgressiveImageLoaderModule.forRoot(<IImageLoaderOptions>{
      // rootMargin must be specified in pixels or percent
      rootMargin: '30px',
      threshold: 0.1,
      // css filter
      filter: 'blur(3px) drop-shadow(0 0 0.75rem crimson)',
      // image width / height ratio for image holder
      // loading image in placeholder. Can be URL or base64
    }),
    NgBusyModule.forRoot(new BusyConfig({
      message: 'Loading ...',
      backdrop: true
  }))
  ],
  providers: [ConfirmationService, TreeDragDropService, MessageService, SumPipeModule,  NgxSpinnerService]
})
export class AdminModule { }


