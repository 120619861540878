import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CrudService } from './crud.service';

import { Pages } from '../models/pages';

@Injectable({
  providedIn: 'root'
})

export class PagesService extends CrudService<Pages, number> {

  constructor(protected _http: HttpClient) {
    super(_http, `${environment.baseUrl}/pages`);
  }

  
}



