<p-sidebar [(visible)]="filterSidebar" #op position="right">
    <ng-template pTemplate>
        <div class="p-col-12">

            <div class="p-field p-grid">
                <div class="p-col-12">

                    <input type="text" [style]="{ width: '100%' }" pInputText [(ngModel)]="nameFilter"
                        placeholder="Cerca" />
                </div>
            </div>




            <div class="p-field p-grid">
                <div class="p-col-12">
                    <p-dropdown [options]="featuredPersons" [virtualScroll]="true" filter="true" [showClear]="true"
                        placeholder="Scegli" [(ngModel)]="personFilter" [style]="{ width: '100%' }" optionLabel="name"
                        optionValue="name">

                    </p-dropdown>


                </div>
            </div>

            <div class="p-field p-grid">
                <div class="p-col-6">
                    <button pButton type="reset" class="p-button p-button-secondary btn-block" label="annulla"
                        (click)="reset()">
                    </button>
                </div>

                <div class="p-col-6">
                    <button pButton type="submit" class="p-button p-button-primary btn-block" label="cerca"
                        (click)="load()">
                    </button>
                </div>

            </div>

        </div>

    </ng-template>
</p-sidebar>

<!-- <div *ngFor="let col of cols" [ngSwitch]="col.field">

    <div class="tag" *ngIf="dt.filters[col.field]">{{dt.filters[col.field]? dt.filters[col.field].value : ''}}</div>
</div> -->


<div class="top-main-wrapper">

    <div class="dash-header">
        <div class="dash-title">

            <div class="p-field p-grid">
                <div class="p-col-6 p-md-6">
                    <h3>Reviews</h3>
                </div>
                <div class="p-col-6 p-md-6">


                    <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf" label="Esporta" (click)="exportPdf()"
                        class="p-button-options p-mr-2 pull-right"  tooltipPosition="bottom"></button> -->
                    <button type="text" pButton label="Filter" icon="pi pi-filter"
                        class="p-button p-button-options p-mr-2 pull-right" (click)="filterSidebar = true"></button>

                    <button pButton pRipple icon="pi pi-plus" [routerLink]="['/admin/startups/create']" label="Aggiungi"
                        class="p-button p-button-primary p-mr-2 pull-right"></button>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="main-inner">



    <div class="p-fluid">
        <div class="p-field p-grid list">
            <div class="p-col-12 p-md-12">

                <div class="filter-options" *ngIf="nameFilter">

                    <div class="p-field p-grid">

                        <div class="p-col-12 p-md-12">
                            <div class="item-size pull-left">
                                <span class="filter-tag" *ngIf="nameFilter">Nome: {{nameFilter}} <span class="close"><i
                                            class="pi pi-times" (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="descriptionFilter">Descrizione: {{descriptionFilter}}
                                    <span class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="codeFilter">Codice: {{codeFilter}} <span
                                        class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="codeIntFilter">Codice Intì.: {{codeIntFilter}} <span
                                        class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>
                                <span class="filter-tag" *ngIf="brandFilter">Brand: {{brandFilter}} <span
                                        class="close"><i class="pi pi-times" (click)="reset()"></i></span> </span>

                            </div>
                        </div>
                    </div>
                </div>

                <div
                    *ngFor=" let review of reviews  | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: count }; let i = index">
                </div>


                <p-table #dt [columns]="cols" [value]="reviews" [rows]="10" [responsive]="false" [autoLayout]="true"
                    [paginator]="false">

                    <ng-template pTemplate="body" let-review let-columns="columns">
                        <tr>
                         

                            <td>     <p-tag *ngIf="review.is_active == 1" class="cp" severity="success"
                               >published</p-tag>

                                <p-tag *ngIf="review.is_active == 0" class="cp" severity="danger"
                               >unpublished</p-tag>
                            </td>
                          
                            <td>   <p-rating
                                [(ngModel)]="review.value1"
                                [cancel]="false"
                                [readonly]="true"
                              ></p-rating></td>
                         

                            <td><a  [routerLink]="['/admin/startups/edit', review.startups.id]">{{review.startups.startup_name}}</a></td>

                           
                         

                            <td class="action">

                             
                                <button pButton pRipple label="edit" icon="pi pi-pencil" class="p-button p-button-primary pull-right p-mr-2"
                                [routerLink]="['/admin/reviews/edit', review.id]"></button>


                   
                                <button pButton pRipple label="view" icon="pi pi-eye"
                                class="p-button p-button-outline pull-right p-mr-2"
                                (click)="edit(review)"></button>


                        


                               <!--  <button pButton pRipple icon="pi pi-trash"
                                    class="p-button p-button-danger pull-right p-mr-2"
                                    (click)="onDelete(startup.id)"></button>

                                <button pButton pRipple icon="pi pi-eye"
                                    class="p-button p-button-primary pull-right p-mr-2"
                                    (click)="edit(startup)"></button>
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button p-button-success pull-right  p-mr-2"
                                    [routerLink]="['/admin/startups/edit', startup.id]"></button> --> 
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="p-grid">
                    <div class="p-col-12 p-md-4">
                        <p class="p-pt-1">
                            <span class="count-item pull-left">{{pageSize * page - pageSize +1 }} - {{pageSize * page}}
                                di {{count}} risultati</span>
                        </p>
                    </div>
                    <div class="p-col-12 p-md-8">
                        <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true"
                            (pageChange)="handlePageChange($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-sidebar [(visible)]="reviewDialog" position="right" [style]="{width:'40%'}">
    <ng-template pTemplate="content">
        <div class="p-col-12 p-md-12">


            <p-rating
            [(ngModel)]="review.value1"
            [cancel]="false"
            [readonly]="true"
          ></p-rating>
            <p [innerHTML]="review.description"></p>
          


           


        </div>
    </ng-template>

</p-sidebar>

{{error}}

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>


<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #publish>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="publish.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="publish.accept()"></button>
    </p-footer>

</p-confirmDialog>