import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';


@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: [ './password-recover.component.css']
})
export class PasswordRecoverComponent implements OnInit {

  loginForm: UntypedFormGroup;
  submitted = false;
  returnUrl: string;
  error: {};
  loginError: string;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      user_email: ['', Validators.required],
    });


    this.spinner.hide();

  }

  get user_email() { return this.loginForm.get('user_email'); }

  onSubmit() {
    this.submitted = true;
    this.authService.recover_password(this.user_email.value).subscribe((data) => {
      if (this.authService.isLoggedIn()) {
        this.loginError = 'Check email.';
        this.loginForm = this.fb.group({
          user_email: '',
        });
        localStorage.removeItem('currentUser');
      } else {
        this.loginError = 'Username or password is incorrect.';
      }
    },
      error => this.error = error
    );
  }
}
