<p-toast key="myKeyUpload"></p-toast>

<div class="p-grid">
  <div class="p-col-12 p-md-12">
    <h4>Foto Copertina</h4>
  </div>
</div>
<div *ngFor="let progressInfo of progressInfos" class="mb-2">
  <span>{{ progressInfo.fileName }}</span>
  <p-progressBar [value]="progressInfo.value"></p-progressBar>
</div>

<div class="row">
  <div class="col-8">
    <div class="button_outer_big">
      <div class="btn_upload">
        <input type="file" accept="image/*" (change)="selectFiles($event)" />
        Carica Immagine
      </div>
    </div>
  </div>

  <div class="col-4">
    <button
      pButton
      class="p-button p-button-success p-mr-2"
      [disabled]="!selectedFiles"
      (click)="uploadFiles()"
    >
      Upload
    </button>
    <br /><br />
  </div>
</div>

<div *ngIf="message.length" class="alert alert-secondary my-3" role="alert">
  <div
    *ngFor="let msg of message; let i = index"
    class="p-message p-message-success"
  >
    <div class="p-message-wrapper">
      <span class="p-message-summary">{{ msg }}</span>
    </div>
  </div>
</div>

<div class="p-grid">
  <div *ngFor="let preview of previews" class="p-col-6">
    <img [src]="preview" class="img-responsive" />
  </div>
</div>

<div class="p-grid" *ngIf="imageInfos">
  <div class="p-col-12 p-md-12">
    <div class="p-fluid">
      <div class="blog-form">
        <p-image
          [src]="path + element + '/' + imageInfos.startup_logo"
          alt="Image"
          width="100%"
          preview="true"
        >
        </p-image>
      </div>
    </div>
  </div>
</div>
