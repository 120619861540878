import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Blog } from "../../../models/blog";
import { ConfirmationService, SelectItem } from "primeng/api";
import { CategoryService } from "../../../services/categories.service";
import { Category } from "../../../models/category";
import { MessageService } from "primeng/api";
import { Clients } from "src/app/models/clients";
import { Works } from "src/app/models/works";
import { Locations } from "src/app/models/locations";
import { formatDate } from "@angular/common";
import "jspdf-autotable";
import {
  PARAM_STARTUPS_LIKES_PATH,
  PARAM_STARTUPS_PATH,
  PARAM_STARTUPS_REVIEW_PATH,
  STATUS_PRODUCTS,
} from "../../constants/constants";
import { Table } from "primeng/table";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { StartupsService } from "src/app/services/startups.service";
import { DatesService } from "src/app/services/dates.service";
import { Startups } from "src/app/models/startups";
import { StartupsReviewService } from "src/app/services/startups_review.service";
import { Reviews } from "src/app/models/review";
import { StartupsLikesService } from "src/app/services/startups_likes.service";
import { Startups_likes } from "src/app/models/startups_likes";

@Component({
  selector: "app-manage-likes",
  templateUrl: "./manage-likes.component.html",
})
export class ManageLikesComponent implements OnInit {


  likes: Startups_likes[] = [];
  like: Startups_likes;

  cols: any[];
  colsData: any[];
  colsVariations: any[];
  colsDataVariations: any[];
  exportColumns: any[];
  _selectedColumns: any[];
  selectedWorks: any[];
  selectedSkills: any[];
  selectedSkillsVariations: any[];

  brands: any = [];
  featuredPersons: any = [];
  filterSidebar: boolean = false;
  loading: boolean;
  currentIndex = 1;
  productsData: any = [];
  date: Date;
  skillsArray: any = [];
  categories: any = [];
  category: Category;
  error: string;
  private id: number;
  clients: any = [];
  client: Clients;
  reviewDialog: boolean = false;
  works_id: any;
  category_id: any;
  status: any;
  currentUser: any;
  productsVariations: any;
  technical_datas: any = [];
  skills: any[] = [];
  batches: any[];
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  public base_path: string;
  basePath: string;
  pageOfItems: Array<any>;
  searchWrapper: boolean = false;
  nameFilter: string;
  descriptionFilter: string;
  codeFilter: string;
  codeIntFilter: string;
  brandFilter: string;
  curatorFilter: any;
  orderBy: string;
  orderByType: string;
  tipologyFilter: string;
  destinationFilter: string;
  areaFilter: string;
  showDialog() {
    this.reviewDialog = true;
  }
  _start;
  _limit;
  myDate = formatDate(new Date(), "dd/MM/yyyy", "en");
  @ViewChild("dt", { static: false }) public dt: Table;

  constructor(
    private startupsLikesService: StartupsLikesService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private datesService: DatesService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService
  ) {
    this.status = STATUS_PRODUCTS;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");

    this.basePath = window.location.pathname;
    if (this.route.snapshot.queryParamMap.has("page")) {
      this.page = +this.route.snapshot.queryParamMap.getAll("page");
    }
    if (this.route.snapshot.queryParamMap.has("size")) {
      this.pageSize = +this.route.snapshot.queryParamMap.getAll("size");
    }
    if (this.route.snapshot.queryParamMap.has("name")) {
      this.curatorFilter = this.route.snapshot.queryParamMap.get("name");
    }
    if (this.route.snapshot.queryParamMap.has("description")) {
      this.descriptionFilter =
        this.route.snapshot.queryParamMap.get("description");
    }
  }

  ngOnInit() {
    const userId = this.currentUser.user_id;
    this.spinner.show();

    this.load();
    this.cols = [
      { field: "startup_name", header: "TitoNomelo" },

      { field: "is_active", header: "Attivo" },
    ];
    this._selectedColumns = this.cols;
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.spinner.hide();
  }

  getRequestParams(
    searchTitle: string,
    categoryTitle: string,
    codeTitle: string,
    codeIntTitle: string,
    page: number,
    pageSize: string | number,
    orderBy: string,
    orderByType: string
  ): any {
    // tslint:disable-next-line:prefer-const
    let path = PARAM_STARTUPS_LIKES_PATH;
    let params: any = {};
    let adder = "?";
    if (page) {
      params[`page`] = page - 1;
      path += adder + "page=" + (page - 1);
      adder = "&";
    }
    if (searchTitle) {
      params[`name`] = searchTitle;
      path += adder + "name=" + searchTitle;
      adder = "&";
    }
    if (categoryTitle) {
      params[`description`] = categoryTitle;
      path += adder + "description=" + categoryTitle;
      adder = "&";
    }

    if (codeTitle) {
      params[`code`] = codeTitle;
      path += adder + "code=" + codeTitle;
      adder = "&";
    }

    if (codeIntTitle) {
      params[`code_int`] = codeIntTitle;
      path += adder + "code_int=" + codeIntTitle;
      adder = "&";
    }

    if (pageSize) {
      params[`size`] = pageSize;
      path += adder + "size=" + pageSize;
    }

    if (orderBy) {
      params[`orderBy`] = orderBy;
      adder + "orderBy=" + orderBy;
    }

    if (orderByType) {
      params[`orderByType`] = orderByType;
      adder + "orderByType=" + orderByType;
    }

    window.history.replaceState({}, "", path);
    return params;
  }

  reset(): void {
    this.nameFilter = "";
    this.descriptionFilter = "";
    this.codeFilter = "";
    this.codeIntFilter = "";
    this.brandFilter = "";
    this.load();
  }

  load(): void {
    const params = this.getRequestParams(
      this.nameFilter,
      this.descriptionFilter,
      this.destinationFilter,
      this.areaFilter,
      this.page,
      this.pageSize,
      this.orderBy = "asc",
      this.orderByType = "id"
    );
    this.startupsLikesService.list(params).subscribe((pData) => {
      this.likes = pData;
      this.count = this.startupsLikesService.size;
    });
  }

  public handlePageChange(event): void {
    this.page = event;
    this.load();
  }

  edit(like: Startups_likes) {
    this.spinner.show();
    this.like = { ...like };
    this.reviewDialog = true;
    this.spinner.hide();
  }

  goToUrl(startup_url: any): void {
    window.open(startup_url, "_blank");

}

  hideDialog() {
    this.reviewDialog = false;
  }

  onDelete(id: number, title: string) {
    this.confirmationService.confirm({
      message: "Sei sicuro di volerlo cancellare = " + id,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.startupsLikesService.delete(+id).subscribe(
          (res) => {
            console.log(res);
            this.ngOnInit();
            this.messageService.add({
              key: "myKey1",
              severity: "warn",
              summary: "Attenzione",
              detail: "Cancellazione avvenuto con successo",
            });
          },
          (error) => (this.error = error)
        );
      },
    });
  }
}
