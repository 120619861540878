import { Component, ElementRef, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ViewChild } from "@angular/core";
import { Clients } from "../../../../models/clients";
import { Category } from "../../../../models/category";
import { UntypedFormControl } from "@angular/forms";
import { CategoryService } from "../../../../services/categories.service";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import * as moment from "moment";
import {
  FISCAL_CODE_VALIDATOR_REGEX,
  SEX_LIST,
  STATE_LIST,
  BUSINESS_STATE_LIST,
  STATE_PUBLISHED,
  FEATURED_OPTIONS,
} from "../../../constants/constants";
import { ComuniService } from "src/app/services/comuni.service";
import { Comuni } from "src/app/models/comuni";
import { Location } from "@angular/common";
import { Billings } from "src/app/models/billings";
import { Personal_data } from "src/app/models/personal_data";
import { NgxSpinnerService } from "ngx-spinner";
import { NewsService } from "src/app/services/news.service";
import { PagesService } from "src/app/services/pages.service";
import { Pages } from "src/app/models/pages";
import { FeaturesTechnicalDataService } from "src/app/services/features_technical_data";

@Component({
  selector: "app-categories-form",
  templateUrl: "./categories-form.component.html",
})
export class CategoriesFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;

  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  clients: Clients;
  client: Clients;

  clientsList: any = [];
  clientList: Clients;

  categories: any = [];
  category: Category;
  checked: boolean = true;
  selectedValue: string;
  comuni: Comuni;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  blogForm: UntypedFormGroup;
  typeList: any[];
  featuresTechnicalData: Category[] = [];
  nameFilter: string;
  descriptionFilter: string;
  codeFilter: string;
  codeIntFilter: string;
  brandFilter: string;
  cities: Clients[];
  format1: string = "";
  format2: string = "";
  selectedCity: Clients;
  selectedCategories: Category;
  selectedDate: Date;
  selectedPagesPublished: any[];
  date: Date;
  appointments: any = [];
  is_featured = "0";
  deleteButton: boolean;
  statePublished: any[];
  currentUser: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  skillsValues: any = [];
  stateOptions: any[];
  cols: any[];
  exportColumns: any[];
  selectedStatePublished: SelectItem[] = [];
  _selectedColumns: any[];
  businessStateOptions: any[];
  personal_datas: any = [];
  personal_data: Personal_data;
  personName: string;
  element: Category;
  id: number;
  pagess: any = [];
  pages: Pages;

  trackByFn(index, item) {
    return item.id;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private categoryService: CategoryService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (this.date) {
      this.selectedDate = new Date(this.date);
    }
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");
    this.stateOptions = STATE_LIST;
    this.statePublished = FEATURED_OPTIONS;
    const userId = this.currentUser.user_id;
  }

  ngOnInit() {
    this.spinner.show();

    const id = this.route.snapshot.paramMap.get("id");

    if (id) {
      this.pageTitle = "Modifica Categoria";
      this.deleteButton = true;

      this.categoryService.getId(+id).subscribe((res) => {
        this.element = res;

        this.blogForm.patchValue({
          category_name: res.category_name,
          category_description: res.category_description,
          seo_url: res.seo_url,
          seo_title: res.seo_title,
          featured: res.featured,
          seo_description: res.seo_description,
          is_active: res.is_active,
          id: res.id,
        });

        this.id = res.id;
      });
    } else {
      this.deleteButton = false;
      this.pageTitle = "Aggiungi Categoria";
    }

    this.blogForm = this.fb.group({
      id: [""],
      category_name: ["", Validators.required],
      category_description: [""],
      seo_url: [""],
      seo_title: [""],
      seo_description: [""],
      is_active: ["0"],
      featured: ["0"],
    });



    setTimeout(() => {
      this.spinner.hide();
    }, 200);
  }




  onSubmit() {
    const formData = new FormData();
    formData.append("category_name", this.blogForm.get("category_name").value);
    formData.append("category_description", this.blogForm.get("category_description").value);
    formData.append("is_active", this.blogForm.get("is_active").value);
    formData.append("featured", this.blogForm.get("featured").value);
    formData.append("seo_url", this.blogForm.get("seo_url").value);
    formData.append("seo_title", this.blogForm.get("seo_title").value);

    const id = this.blogForm.get("id").value;

    if (id) {
      this.categoryService.update(formData, +id).subscribe(
        (res) => {
          if (res.status == "error") {
            this.uploadError = res.message;
            this.messageService.add({
              key: "myKey1",
              severity: "warn",
              summary: "Attenzione",
              detail: "Salvato senza sucesso",
            });
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Attenzione",
              detail: "Salvato con sucesso",
            });
            // this._location.back();
          }
        },
        (error) => (this.error = error)
      );
    } else {
      this.categoryService.create(formData).subscribe(
        (res) => {
          if (res.status === "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Attenzione",
              detail: "Salvato con sucesso",
            });
            this.router.navigate(["/admin/pages"]);
          }
        },
        (error) => (this.error = error)
      );
    }
  }
}
