import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfirmationService, MenuItem, SelectItem } from "primeng/api";
import { CategoryService } from "../../../services/categories.service";
import { Category } from "../../../models/category";
import { MessageService } from "primeng/api";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { NgxSpinnerService } from "ngx-spinner";
import {
  LEVEL_PUBLISHED,
  PARAM_NEWS_PATH,
} from "../../constants/constants";
import { NewsService } from "src/app/services/news.service";
import { News } from "src/app/models/news";

@Component({
  selector: "app-manage-news",
  templateUrl: "./manage-news.component.html",
})
export class ManageNewsComponent implements OnInit {
  newss: any = [];
  news: News;
  filterSidebar: boolean;
  categories: any = [];
  category: Category;
  error: string;
  comuni: any = [];
  cols: any[];
  exportColumns: any[];
  _selectedColumns: any[];
  loading: boolean;
  totalRecords: string;
  currentUser: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  public base_path: string;
  basePath: string;
  pageOfItems: Array<any>;
  searchWrapper: boolean = false;
  nameFilter: string;
  descriptionFilter: string;
  codeFilter: string;
  codeIntFilter: string;
  brandFilter: string;
  selectedLevelPublished: SelectItem[] = [];
  levelPublished: any[];
  productDialog: boolean = false;

  showDialog() {
    this.productDialog = true;
  }

  trackByFn(index, item) {
    return item.id;
  }

  constructor(
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private newsService: NewsService,
    private categoryService: CategoryService,
    private confirmationService: ConfirmationService
  ) {
    const doc = new jsPDF();
    this.levelPublished = LEVEL_PUBLISHED;

  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");
    const userId = this.currentUser.user_id;

    this.spinner.show();
    this.load();

    this.cols = [
      { field: "level", header: "News" },
      { field: "page_title", header: "Titolo" },
      { field: "is_active", header: "Attivo" },
    ];

    this._selectedColumns = this.cols;
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.spinner.hide();
  }

  getRequestParams(searchTitle, categoryTitle, page, pageSize): any {
    // tslint:disable-next-line:prefer-const
    let path = PARAM_NEWS_PATH;
    const params = {};
    let adder = "?";
    if (page) {
      params[`page`] = page - 1;
      path += adder + "page=" + (page - 1);
      adder = "&";
    }
    if (searchTitle) {
      params[`page_title`] = searchTitle;
      path += adder + "page_title=" + searchTitle;
      adder = "&";
    }
    if (categoryTitle) {
      params[`level`] = categoryTitle;
      path += adder + "level=" + categoryTitle;
      adder = "&";
    }

    if (pageSize) {
      params[`size`] = pageSize;
      path += adder + "size=" + pageSize;
    }
    window.history.replaceState({}, "", path);

    return params;
  }

  reset(): void {
    this.nameFilter = "";
    this.descriptionFilter = "";
    this.codeFilter = "";
    this.codeIntFilter = "";
    this.brandFilter = "";
    this.load();
  }

  load(): void {
    const params = this.getRequestParams(
      this.nameFilter,
      this.descriptionFilter,
      this.page,
      this.pageSize
    );
    this.newsService.list(params).subscribe((pData) => {
      this.newss = pData;
      this.count = this.newsService.size;
    });
  }

  public handlePageChange(event): void {
    this.page = event;
    this.load();
  }

  public selectionItemForFilter(e) {
    const colsTempor = e.value;
    colsTempor.sort(function (a, b) {
      return a.index - b.index;
    });
    this.cols = [];
    this.cols = colsTempor;
    if (e.value.length > 10) {
      e.value.pop();
    }
  }

  onDelete(id: number, title: string) {
    this.confirmationService.confirm({
      message: "Sei sicuro di volerlo cancellare",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.newsService.delete(+id).subscribe(
          (res) => {
            console.log(res);
            this.ngOnInit();
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Attenzione",
              detail: "Cancellazione avvenuto con successo",
            });
          },
          (error) => {
            this.error = error;
            this.messageService.add({
              key: "myKey2",
              severity: "warn",
              summary: "Attenzione",
              detail: "Errore backend",
            });
          }
        );
      },
    });
  }
}
