<section class="cmspage mtb-40">
  <div class="container">
    <div class="page-desc">
      <h1>{{page?.title}}</h1>
      <div class="page-desc" [innerHTML]="page?.description"></div>
    </div>
    <div class="service-error" *ngIf="error">
      <h1>{{error.errorTitle}}</h1>
      <h3>{{error.errorDesc}}</h3>
    </div>
  </div>
</section>