import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Chart } from "chart.js";
import { BlogService } from "../../services/blog.service";
import { Blog } from "../../models/blog";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { DASHBOARD, PAGES, TYPE_LIST } from "../constants/constants";
import { CategoryService } from "src/app/services/categories.service";
import { Category } from "src/app/models/category";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { Clients } from "src/app/models/clients";
import { Appointments } from "src/app/models/appointments";
import * as $ from "jquery";
import { formatDate } from "@angular/common";
import { Locations } from "src/app/models/locations";
import { Employees } from "src/app/models/employees";
import { Works } from "src/app/models/works";
import * as moment from "moment";
import { Billings } from "src/app/models/billings";
import { NgxSpinnerService } from "ngx-spinner";
import "moment/locale/it"; // without this line it didn't work
import { SupportsService } from "src/app/services/supports.service";
import { Supports } from "src/app/models/supports";
import { Projects } from "src/app/models/projects";

import { WarehouseCheckins } from "src/app/models/warehouse_checkins";
import { NewsService } from "src/app/services/news.service";
import { News } from "src/app/models/news";
import { StartupsService } from "src/app/services/startups.service";
import { Requests } from "src/app/models/requests";
import { Startups } from "src/app/models/startups";
import { UsersService } from "src/app/services/users.service";
import { Users } from "src/app/models/users";
import { StartupsLikesService } from "src/app/services/startups_likes.service";
import { Startups_likes } from "src/app/models/startups_likes";
import { StartupsReviewService } from "src/app/services/startups_review.service";
import { Reviews } from "src/app/models/review";

moment.locale("it");

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
})
export class AdminDashboardComponent implements OnInit {
  calendarOptions: any;
  events: any;
  newss: any = [];
  chart: [];
  requests: any = [];
  works: any = [];
  work: Works;
  clientsCount: any;
  supports: any = [];
  support: Supports;

  billings: any = [];
  billing: Billings;

  startups: Startups[] = [];
  startup: Startups;
  likes: Startups_likes[] = [];
  reviews: Reviews[] = [];
  news: News[] = [];

  productsCount: any;
  error: string;
  blogForm: UntypedFormGroup;
  typeList: any;
  cities: Blog[];
  format1: string = "";
  format2: string = "";
  selectedCity: Blog;
  uploadError: string;
  selectedCategories: Category;
  selectedDate: Date;
  date: Date;
  selectedWorks: any[];
  categories: any = [];
  category: Category;
  productDialog: boolean = false;
  appointmentsDialog: boolean = false;
  users: Users[] = [];
  clientsWorks: any = [];
  user: Users;
  comuni: any = [];
  displayEvent: any;
  currentUser: any;
  items: any;
  appointmentsCount: Appointments;
  billingsCount: Billings;
  billingsCountTotal: Billings;
  projects: any = [];
  project: Projects;
  billingsCountTotalNotPaid: Billings;
  category_id: string;
  canvas: any;
  ctx: any;
  yAxes: [];
  xAxes: [];
  chartsCount: any;
  chartsCountNone: any;
  chartsCountData: any = [];
  chartsCountDataTotal: string;
  data1 = [];
  data2 = [];
  count = 0;
  countStartups = 0;
  countNews = 0;
  countClients = 0;
  countLikes = 0;
  countReviews = 0;

  countClientsWorks = 0;
  request: Requests;
  pageSize = 3;
  page = 1;
  myDate = formatDate(new Date(), "dd/MM/yyyy", "en");
  searchDate = formatDate(new Date(), "yyyy-MM-dd", "en");
  cols: any[];
  colsData: any[];
  colsVariations: any[];
  colsDataVariations: any[];
  exportColumns: any[];
  _selectedColumns: any[];
  responsiveOptions: any;

  trackByFn(index, item) {
    return item.id;
  }

  myMonth = formatDate(new Date(), "dd/MM/yyyy", "en");
  nameFilter: string;
  descriptionFilter: string;
  dateFromFilter: string;
  dateToFilter: string;
  currentDate: moment.Moment = moment();
  currentTime: string = moment().format(" MMMM YYYY");

  constructor(
    private spinner: NgxSpinnerService,
    private startupsService: StartupsService,
    private startupsLikesService: StartupsLikesService,
    private startupsReviewService: StartupsReviewService,
    private usersService: UsersService
  ) {
    this.typeList = TYPE_LIST;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");
    this.items = DASHBOARD;
    this.responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: "768px",
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: "560px",
        numVisible: 3,
        numScroll: 3,
      },
    ];
  }

  ngOnInit() {
    const userId = this.currentUser.user_id;
    this.spinner.show();
    this.getStartups();
    this.getClients();
    this.getLikes();
    this.getReviews();
    this.cols = [{ field: "page_title", header: "Titolo" }];

    this._selectedColumns = this.cols;
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    this.spinner.hide();
  }

  ngAfterViewInit() {
    //this.getChartsCount();
    //this.chartCanvas();
  }

  goToUrl(startup_url: any): void {
    window.open(startup_url, "_blank");

}


  getRequestParams(page, searchTitle, categoryTitle, pageSize): any {
    // tslint:disable-next-line:prefer-const
    const params = {};
    let adder = "?";
    if (page) {
      params[`page`] = page - 1;
      adder + "page=" + (page - 1);
      adder = "&";
    }
    if (searchTitle) {
      params[`name`] = searchTitle;
      adder + "date_from=" + searchTitle;
      adder = "&";
    }
    if (categoryTitle) {
      params[`description`] = categoryTitle;
      adder + "date_to=" + categoryTitle;
      adder = "&";
    }
    if (pageSize) {
      params[`size`] = pageSize;
      adder + "size=" + pageSize;
    }

    return params;
  }

  getStartups() {
    const params = this.getRequestParams(
      (this.nameFilter = ""),
      (this.descriptionFilter = ""),
      (this.page = 0),
      (this.pageSize = 5)
    );

    this.startupsService.list(params).subscribe((pData) => {
      this.startups = pData;
      this.countStartups = this.startupsService.size;
    });
  }

  edit(request: Requests) {
    this.request = { ...request };
    this.productDialog = true;
  }

  getClients() {
    const params = {
      page: 0,
      size: 7,
      account_type: 1
    };

    this.usersService.list(params).subscribe((pData) => {
      this.users = pData;
      this.countClients = this.usersService.size;
    });
  }


  getLikes() {
    const paramsLikes = this.getRequestParams(
      (this.nameFilter = ""),
      (this.descriptionFilter = ""),
      (this.page = 0),
      (this.pageSize = 4)
    );

    this.startupsLikesService.list(paramsLikes).subscribe((pData) => {
      this.likes = pData;
      this.countLikes = this.startupsLikesService.size;
    });
  }

  getReviews() {
    const paramsLikes = {
      page: 0,
      size: 4,
    }
   

    this.startupsReviewService.list(paramsLikes).subscribe((pData) => {
      this.reviews = pData;
      this.countReviews = this.startupsReviewService.size;
    });
  }
}
