import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CrudService } from './crud.service';
import { Comuni } from '../models/comuni';
import { Province } from '../models/province';
import { Sector } from '../models/sector';

@Injectable({
  providedIn: 'root'
})

export class SectorService extends CrudService<Sector, number> {

  constructor(protected _http: HttpClient) {
    super(_http, `${environment.baseUrl}/sectors`);
  }

}



