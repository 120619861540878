import { Component, ElementRef, Inject, OnInit } from "@angular/core";
import { AppointmentsService } from "../../../services/appointments.service";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
  FormGroup,
  AbstractControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ViewChild } from "@angular/core";
import { Blog } from "../../../models/blog";
import { Category } from "../../../models/category";
import { UntypedFormControl } from "@angular/forms";
import {
  ConfirmationService,
  Message,
  MessageService,
  SelectItem,
} from "primeng/api";
import * as moment from "moment";
import {
  TYPE_LIST,
  STATUS_PRODUCTS,
  STATE_LIST,
  STATE_PUBLISHED,
  FEATURED_OPTIONS,
  COUNTRIES,
} from "../../constants/constants";
import { DOCUMENT, Location } from "@angular/common";
import { Works } from "src/app/models/works";
import { Locations } from "src/app/models/locations";
import { map, tap } from "rxjs/operators";
import { Technical_data } from "src/app/models/technical_data";
import { Observable } from "rxjs";
import { SlideshowService } from "src/app/services/slideshow.service";
import { Slideshow } from "src/app/models/slideshow";
import { Tours } from "src/app/models/tours";
import { NgxSpinnerService } from "ngx-spinner";
import { FeaturesTechnicalDataService } from "src/app/services/features_technical_data";
import { SeoService } from "src/app/services/seoservice";
import { StartupsService } from "src/app/services/startups.service";
import { DatesService } from "src/app/services/dates.service";
import { Startups } from "src/app/models/startups";
import { CategoryService } from "src/app/services/categories.service";
import { UsersService } from "src/app/services/users.service";
import { Users } from "src/app/models/users";

export interface fPairs {
  label: number;
  value: number;
}

@Component({
  selector: "app-likes-form",
  templateUrl: "./likes-form.component.html",
})
export class LikesFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;
  pageTitle: string;
  error: string;
  messages: Message[];
  uploadError: string;
  imagePath: any;
  blogs: Blog;
  blog: Blog;
  id: number;
  selectedCountry: SelectItem[];
  category: Category;
  works: any = [];
  work: Works;

  checked: boolean = true;
  selectedValue: string;

  blogForm: FormGroup;
  datesForm: FormGroup;
  user: Users;
  typeList: any[];
  status: any[];
  statePublished: any[];
  stateOptions: any[];
  technical_datas: any = [];
  technical_data: Technical_data;
  selectedStatePublished: SelectItem[] = [];
  count = 0;
  tags: any = [];
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  imageInfos?: Observable<any>;
  dates: any = [];
  description: any;
  selectedFeartures: SelectItem[] = [];
  selectedFeatures: SelectItem[] = [];
  locations: any = [];
  location: Locations;
  calendarOptions: any;
  cities: Blog[];
  selectedCity: Blog;
  selectedClients: SelectItem[];
  panelClosed = false;
  orderBy: string;
  orderByType: string;
  tour_id: string;
  selectedDate: Date;
  date: Date;
  works_id: any;
  basePath: string;
  category_id: any;
  public dataValues: object;
  pages: any;
  currentUser: any;

  fPairs: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  skillsForm: UntypedFormGroup;
  skillsValues: any = [];
  datesValues: any = [];
  trackByFn(index, item) {
    return item.id;
  }
  element: Startups;
  slideshows: any = [];
  slideshow: Slideshow;
  documents: any = [];
  selectedItemDisplay: string;
  featuresTechnicalData: Category[] = [];
  data: any;
  displayEvent: any;
  visibleDates: boolean;
  cols: any[];
  colsData: any[];
  colsVariations: any[];
  page = 1;
  pageSize = 10;
  pageSizes = [5, 10, 15];
  tourTitle: string;
  startupStatus: any;
  categoriesList: Category[] = [];
  selectedCategories: SelectItem[] = [];
  featuredOptions: any[];
  userIdentification: number;
  countriesList: any[];
  startupUrl: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private _location: Location,
    private startupsService: StartupsService,
    private usersService: UsersService,
    private spinner: NgxSpinnerService,
    private categoryService: CategoryService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private featuresTechnicalDataService: FeaturesTechnicalDataService,
    private slideshowService: SlideshowService,
    private route: ActivatedRoute
  ) {
    this.basePath = window.location.pathname;
    if (this.route.snapshot.queryParamMap.has("page")) {
      this.page = +this.route.snapshot.queryParamMap.getAll("page");
    }
    if (this.route.snapshot.queryParamMap.has("size")) {
      this.pageSize = +this.route.snapshot.queryParamMap.getAll("size");
    }

    if (this.date) {
      this.selectedDate = new Date(this.date);
    }
    this.typeList = TYPE_LIST;
    this.statePublished = STATE_PUBLISHED;
    this.featuredOptions = FEATURED_OPTIONS;
    this.countriesList = COUNTRIES;
    this.stateOptions = STATE_LIST;
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '[]');

  }

  ngOnInit() {
    this.getCategories();
    const id = this.route.snapshot.paramMap.get("id");


    if (id) {
      this.pageTitle = "Modifica Prodotto";

      this.startupsService.getId(+id).subscribe((res) => {
        this.element = res;
        this.imageInfos = this.slideshowService.getId(+id);

        this.usersService.getId(this.element.user_id).subscribe((res) => {
          this.user = res;
        });

        this.blogForm.patchValue({
          startup_name: res.startup_name,
          startup_headline: res.startup_headline,
          startup_seo_url: res.startup_seo_url,
          startup_description: res.startup_description,
          startup_technology: res.startup_technology,
          startup_email: res.startup_email,
          startup_url: res.startup_url,
          country: res.country,
          status: res.status,
          category_id: res.category_id.split(","),
          twitter: res.twitter,
          user_id: res.user_id,
          linkedin: res.linkedin,
          producthunt: res.producthunt,
          id: res.id,

        });
        this.id = res.id;
        this.tourTitle = res.startup_name;
        this.startupStatus = res.status;
        this.imagePath = res.image;
        this.startupUrl = res.startup_url;
        this.userIdentification = res.user_id; 
      });
    } else {
      this.pageTitle = "Aggiungi Prodotto";
    }

    this.blogForm = this.fb.group({
      id: [''],
      startup_name: ['', Validators.required],
      startup_headline: ['', Validators.required],
      startup_seo_url: ['', Validators.required],
      startup_description: ['', Validators.required],
      startup_technology: [''],
      startup_email: [''],
      startup_url: [''],
      category_id: [''],
      country: [''],
      linkedin: [''],
      status: [''],
      twitter: [''],
      producthunt: [''],
      image: [""],
      user_id: [""],
    });




  }

  
  dayClick(event) {
    this.visibleDates = true;
  }


  get f(): { [key: string]: AbstractControl } {
    return this.blogForm.controls;
  }

  goToUrl(): void {
    window.open(this.startupUrl, "_blank");

}

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.blogForm.get('image').setValue(file);

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imagePath = reader.result;
      };
    }
  }

  removeImageFile() {
    this.imagePath = '';
    console.log(this.myInputVariable.nativeElement.files);
    this.myInputVariable.nativeElement.value = '';
    console.log(this.myInputVariable.nativeElement.files);
  }

  public getCategories() {
    const paramsCategories = [];
    this.categoryService.getAll(paramsCategories).subscribe((pData) => {
      this.categoriesList = pData;
    });

  }
  
  unpublished() {

    const formData = new FormData();

    formData.append('status', '0');
    formData.append('user_published', this.blogForm.get('user_id').value);

    const id = this.blogForm.get('id').value;

      this.startupsService
        .updateStatus(formData, +id)
        .subscribe(
          (res) => {
            if (res.status == 'error') {
              this.uploadError = res.message;
            } else {
              this.messageService.add({
                key: 'myKey1',
                severity: 'success',
                summary: 'Informazioni',
                detail: 'Saved successfully',
              });
              //this.router.navigate(['/admin/products']);
              this.ngOnInit();
            }
          },
          (error) => (this.error = error)
        );
  }

  published() {

    const formData = new FormData();

    formData.append('status', '1');
    formData.append('user_published', this.blogForm.get('user_id').value);

    const id = this.blogForm.get('id').value;

      this.startupsService
        .updateStatus(formData, +id)
        .subscribe(
          (res) => {
            if (res.status == 'error') {
              this.uploadError = res.message;
            } else {
              this.messageService.add({
                key: 'myKey1',
                severity: 'success',
                summary: 'Informazioni',
                detail: 'Saved successfully',
              });
              //this.router.navigate(['/admin/products']);
              this.ngOnInit();
            }
          },
          (error) => (this.error = error)
        );
  }

  initSkill() {
    var formArray = this.fb.array([]);
    const id = this.route.snapshot.paramMap.get("id");

    this.startupsService.skills(+id).subscribe((res) => {
      this.skillsValues = res;
      this.skillsValues.forEach((e) => {
        formArray.push(
          this.fb.group({
            label: [e.label],
            value: [e.value],
          })
        );
      });
    });

    /*formArray.push(this.fb.group({
      qty: [''],
      price: ['']
    })) */

    return formArray;
  }

  private createSkillFormGroup(skill: any): UntypedFormGroup {
    return new UntypedFormGroup({
      label: new UntypedFormControl(skill.label),
      value: new UntypedFormControl(skill.value),
    });
  }

  public addSkill(skill: any) {
    this.skills.push(this.createSkillFormGroup(skill));
  }

  get skills() {
    return this.blogForm.get("skills") as UntypedFormArray;
  }

  addQuantity() {
    this.skills.push(this.newQuantity());
  }

  removeQuantity(i: number) {
    this.skills.removeAt(i);
  }

  newQuantity(): UntypedFormGroup {
    return this.fb.group({
      label: "",
      value: "",
    });
  }

  getselectedCategories() {
    this.selectedCategories = this.category_id.split(',');
  }

  onSubmit() {
    const formData = new FormData();

    formData.append('startup_name', this.blogForm.get('startup_name').value);
    formData.append('startup_description', this.blogForm.get('startup_description').value);
    formData.append('startup_technology', this.blogForm.get('startup_technology').value);
    formData.append('startup_headline', this.blogForm.get('startup_headline').value);
    formData.append('startup_seo_url', this.blogForm.get('startup_seo_url').value);
    formData.append('startup_url', this.blogForm.get('startup_url').value);
    formData.append('startup_email', this.blogForm.get('startup_email').value);
    formData.append('linkedin', this.blogForm.get('linkedin').value);
    formData.append('country', this.blogForm.get('country').value);
    formData.append('twitter', this.blogForm.get('twitter').value);
    formData.append('status', this.blogForm.get('status').value);
    formData.append('producthunt', this.blogForm.get('producthunt').value);
    formData.append('category_id', this.blogForm.get('category_id').value);
    formData.append("image", this.blogForm.get("image").value);
    formData.append('user_id', this.blogForm.get('user_id').value);
    
    const userId = this.blogForm.get('user_id').value;

    const id = this.blogForm.get('id').value;

    if (id) {
      this.startupsService
        .update(formData, +id)
        .subscribe(
          (res) => {
            if (res.status == 'error') {
              this.uploadError = res.message;
            } else {
              this.messageService.add({
                key: 'myKey1',
                severity: 'success',
                summary: 'Informazioni',
                detail: 'Saved successfully',
              });
              //this.router.navigate(['/admin/products']);
            }
          },
          (error) => (this.error = error)
        );
    } else {
      this.startupsService.create(formData).subscribe(
        (res) => {
          if (res.status === 'error') {
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: 'myKey1',
              severity: 'success',
              summary: 'Informazioni',
              detail: 'Saved successfully',
            });
            this.router.navigate(['/dashboard/startups']);
          }
        },
        (error) => (this.error = error)
      );
    }
  }

}
