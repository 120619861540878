<app-topnav></app-topnav>


<div class="top-main-wrapper">

    <div class="dash-header">
        <div class="dash-title">

            <div class="p-field p-grid">
                <div class="p-col-6 p-md-6">
                    <h3>Dashboard</h3>
                </div>
                <div class="p-col-6 p-md-6">

                </div>

            </div>
        </div>
    </div>
</div>

<div class="main-inner">






    <div class="p-grid">

        <div class="p-col-12 p-md-12">

            <div class="p-grid">

                <div class="p-col-12 p-md-7">
                    <div class="p-grid">

                        <div class="p-col-6 p-md-6 p-lg-3">

                            <p-card class="number-card" [routerLink]="['/admin/likes']">

                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <div class="icon-container">
                                            <i tooltipposition="right" class="pi pi-heart"></i>
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-md-12">
                                        <h3 class="mb-0 p-pb-0"> {{countLikes}}</h3>
                                        <p class="p-pt-0">Likes</p>


                                    </div>


                                </div>
                            </p-card>
                        </div>

                        <div class="p-col-6 p-md-6 p-lg-3">

                            <p-card class="number-card" [routerLink]="['/admin/startups']">

                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <div class="icon-container">
                                            <i tooltipposition="right" class="pi pi-list"></i>
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-md-12">
                                        <h3 class="mb-0 p-pb-0"> {{countStartups}}</h3>
                                        <p class="p-pt-0">Startups</p>


                                    </div>


                                </div>
                            </p-card>
                        </div>

                        <div class="p-col-6 p-md-6 p-lg-3">

                            <p-card class="number-card" [routerLink]="['/admin/users']">

                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <div class="icon-container">
                                            <i tooltipposition="right" class="pi pi-user"></i>
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-md-12">
                                        <h3 class="mb-0 p-pb-0"> {{countClients}}</h3>
                                        <p class="p-pt-0">Users</p>


                                    </div>


                                </div>
                            </p-card>
                        </div>
                        <div class="p-col-6 p-md-6 p-lg-3">

                            <p-card class="number-card" [routerLink]="['/admin/reviews']">

                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <div class="icon-container">
                                            <i tooltipposition="right" class="pi pi-comment"></i>
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-md-12">
                                        <h3 class="mb-0 p-pb-0"> {{countReviews}}</h3>
                                        <p class="p-pt-0">Reviews</p>


                                    </div>


                                </div>
                            </p-card>
                        </div>
                    </div>
                    <div class="p-grid">

                        <div class="p-col-12 p-md-12">

                            <h3>Last Startups</h3>

                        </div>
                    </div>

                    <div class="p-grid">

<div class="p-col-12 p-md-12 featured-card">
                        <p-table #dt [columns]="cols" [value]="startups" [rows]="10" [responsive]="false" [autoLayout]="true"
                        [paginator]="false">
    
                        <ng-template pTemplate="body" let-startup let-columns="columns">
                            <tr>
                                <td width="60">
                                    <img
                                    class="img-responsive"  [routerLink]="['/admin/startups/edit', startup.id]"
                    [src]="'https://api.startupinspire.com/assets/startups/' + startup.id + '/' + startup.startup_logo">
                    
                                </td>
    
                                <td><h4 class="p-mb-0 p-mt-0">{{startup.startup_name}}</h4>
                                    <p class="small">{{startup.country}}</p>
                                </td>
                                <td>
                                    <p-tag *ngIf="startup.status == 1" severity="success"
                                    (click)="unpublished(startup.id)">published</p-tag>
    
                                    <p-tag *ngIf="startup.status == 0" severity="danger"
                                    (click)="published(startup.id)">unpublished</p-tag>
    
    
                                    </td>
    
                             
    
                                <td class="action">
    
                                 
                                    <button pButton pRipple label="edit" icon="pi pi-eye" class="p-button p-button-primary pull-right p-mr-2"
                                    [routerLink]="['/admin/startups/edit', startup.id]"></button>
    
                                    <button type="button" pButton pRipple  label="website"
                                    class="p-button p-button-outline pull-right  p-mr-2" (click)="goToUrl(startup.startup_url)"></button>
                       
    
                            
    
    
                                   <!--  <button pButton pRipple icon="pi pi-trash"
                                        class="p-button p-button-danger pull-right p-mr-2"
                                        (click)="onDelete(startup.id)"></button>
    
                                    <button pButton pRipple icon="pi pi-eye"
                                        class="p-button p-button-primary pull-right p-mr-2"
                                        (click)="edit(startup)"></button>
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button p-button-success pull-right  p-mr-2"
                                        [routerLink]="['/admin/startups/edit', startup.id]"></button> --> 
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                       
                    </div>
                </div>

                <div class="p-col-12 p-md-5">

                    <p-card class="table-list">

                 

                                <ng-container *ngFor="let user of users">

                                        <div class="p-grid user-list">
                                            <div class="p-col-12 p-md-8">
                                                <p-tag class="pull-right" *ngIf="user.account_type == 2"
                                                    severity="default">Pro</p-tag>
                                                    <p class="small p-mb-4 p-pb-2"><strong>Account Type: {{user.account_type}} | {{user.u_created_on}}</strong></p>

                                                <h4 class="p-mb-0 p-mt-0">{{user.display_name}}</h4>
                                                <p class="small p-mb-4 p-pb-2">{{user.user_email}}</p>
                                            </div>
                                            <div class="p-col-12 p-md-4">


                                                <button pButton pRipple icon="pi pi-pencil" label="edit"
                                                    class="p-button p-button-success btn-block"
                                                    [routerLink]="['/admin/users/edit', user.id]"></button>

                                            </div>
                                    </div>
                                </ng-container>

                    

                    </p-card>





                </div>
            </div>
        </div>
    </div>
</div>