<app-topnav></app-topnav>

<form [formGroup]="blogForm" (ngSubmit)="onSubmit()">
  <div class="top-main-wrapper">
    <div class="dash-header">
      <div class="dash-title">
        <div class="p-field p-grid">
          <div class="p-col-6 p-md-6">
            <h3>{{ pageTitle }}</h3>
          </div>
          <div class="p-col-6 p-md-6">
            <button
              pButton
              pRipple
              icon="pi pi-save"
              label="salva"
              class="p-button p-button-success p-mr-2 pull-right"
              [disabled]="!blogForm.valid"
            ></button>

            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-chevron-left"
              label=" indietro"
              class="p-button p-button-back pull-right p-mr-2"
              [routerLink]="['/admin/users/']"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-inner">
    <div class="p-fluid">
      <div class="p-field p-grid">
        <div class="p-col-12 p-md-12">
          <div class="p-grid">

            <div class="p-col-12">
                <p-card>
                    <div class="p-grid">

                        <div class="p-col-12 p-md-4">
                <ng-container *ngIf="user">
                    <h3 class="p-mb-0 p-pb-0">{{user.display_name}}</h3>
                    Created: {{ user.u_created_on }}<br />
                    <br>
                  </ng-container>
                  <p-tag
                  class="cp"
                  severity="success"
                  (click)="activateAccount()"
                  >send Activation</p-tag
                >

                <p-tag class="cp" severity="success" *ngIf=""
                  >send Activation</p-tag
                >
                        </div>
                        <div class="p-col-12 p-md-4">
                            <ng-container *ngIf="user">

                            Newsletter: {{ user.newsletter }}<br />
                            Account Type: {{ user.account_type }}
                            </ng-container>
                        </div>
                        <div class="p-col-12 p-md-4">
        
                  <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0"
                      >Attivo <span class="required">*</span></label
                    >
                    <div class="p-col-12 p-md-12">
                      <p-selectButton
                        [options]="stateOptions"
                        formControlName="is_active"
                        optionLabel="label"
                        optionValue="value"
                      ></p-selectButton>
                    </div>
                  </div>

               
                        </div>
                    </div>
                </p-card>
            </div>
          </div>
          <div class="p-grid">

            <div class="p-col-12 p-md-5">
              <p-tabView>
                <p-tabPanel header="Account">
                  <p-card>
                    <div class="p-fluid">
                        <input type="hidden" formControlName="id" />


                     

                      <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0"
                          >Username <span class="required">*</span></label
                        >
                        <div class="p-col-12 p-md-12">
                          <input
                            type="text"
                            pInputText
                            placeholder="Username"
                            formControlName="username"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="p-fluid">
                      <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0"
                          >Nome <span class="required">*</span></label
                        >
                        <div class="p-col-12 p-md-12">
                          <input
                            type="text"
                            pInputText
                            formControlName="display_name"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="p-fluid">
                      <div class="p-field p-grid">
                        <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0"
                          >Email <span class="required">*</span></label
                        >
                        <div class="p-col-12 p-md-12">
                          <input
                            type="text"
                            pInputText
                            formControlName="user_email"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="p-fluid">
                        <div class="p-field p-grid">
                          <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0"
                            >Account Type <span class="required">*</span></label
                          >
                          <div class="p-col-12 p-md-12">
                            <input
                              type="text"
                              pInputText
                              formControlName="account_type"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="p-fluid">
                        <div class="p-field p-grid">
                          <label class="p-col-12 p-mb-2 p-md-12 p-mb-md-0"
                            >Payment Expire <span class="required">*</span></label
                          >
                          <div class="p-col-12 p-md-12">
                            <input
                              type="text"
                              pInputText
                              class="p-mb-2"
                              formControlName="payment_expire"
                            />
                            <p class="small">2119-12-19 08:10:21</p>

                          </div>
                        </div>
                      </div>


                  </p-card>
                </p-tabPanel>

                <p-tabPanel header="Profile">
                    <p-card> 
                     {{profiles | json}}
                    </p-card>
                 </p-tabPanel>
              </p-tabView>
            </div>

            <div class="p-col-12 p-md-7">
              <p-tabView>
                <p-tabPanel header="Startups">
                  <div
                    *ngFor="
                      let startup of startups
                        | paginate
                          : {
                              itemsPerPage: pageSize,
                              currentPage: page,
                              totalItems: count
                            };
                      let i = index
                    "
                  ></div>

                  <p-table
                    #dt
                    [columns]="cols"
                    [value]="startups"
                    [rows]="10"
                    [responsive]="false"
                    [autoLayout]="true"
                    [paginator]="false"
                  >
                    <ng-template
                      pTemplate="body"
                      let-startup
                      let-columns="columns"
                    >
                      <tr>
                        <td width="60">
                          <img
                            class="img-responsive"
                            [routerLink]="['/admin/startups/edit', startup.id]"
                            [src]="
                              'https://api.startupinspire.com/assets/startups/' +
                              startup.id +
                              '/' +
                              startup.startup_logo
                            "
                          />
                        </td>

                        <td>
                          <h4 class="p-mb-0 p-mt-0">
                            {{ startup.startup_name }}
                          </h4>
                          <p class="small">
                            ID: {{ startup.id }} | Likes: {{ startup.likes }}
                          </p>
                        </td>
                        <td>
                          <p-tag
                            *ngIf="startup.status == 1"
                            severity="success"
                            (click)="unpublished(startup.id)"
                            >published</p-tag
                          >

                          <p-tag
                            *ngIf="startup.status == 0"
                            severity="danger"
                            (click)="published(startup.id)"
                            >unpublished</p-tag
                          >
                        </td>
                        <td>{{ startup.country }}</td>

                        <td class="action">
                          <button
                            pButton
                            pRipple
                            label="edit"
                            icon="pi pi-eye"
                            class="p-button p-button-primary pull-right p-mr-2"
                            [routerLink]="['/admin/startups/edit', startup.id]"
                          ></button>

                          <!--  <button pButton pRipple icon="pi pi-trash"
                                                                class="p-button p-button-danger pull-right p-mr-2"
                                                                (click)="onDelete(startup.id)"></button>
                            
                                                            <button pButton pRipple icon="pi pi-eye"
                                                                class="p-button p-button-primary pull-right p-mr-2"
                                                                (click)="edit(startup)"></button>
                                                            <button pButton pRipple icon="pi pi-pencil"
                                                                class="p-button p-button-success pull-right  p-mr-2"
                                                                [routerLink]="['/admin/startups/edit', startup.id]"></button> -->
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>

                  <div class="p-col-12 p-md-8">
                    <pagination-controls
                      previousLabel="Prev"
                      nextLabel="Next"
                      responsive="true"
                      (pageChange)="handlePageChange($event)"
                    >
                    </pagination-controls>
                  </div>
                </p-tabPanel>
              </p-tabView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<p-confirmDialog
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"
  #cd
>
  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-close"
      class="p-button p-button-danger p-mr-2"
      label="No"
      (click)="cd.reject()"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      class="p-button p-button-success p-mr-2"
      label="Si"
      (click)="cd.accept()"
    ></button>
  </p-footer>
</p-confirmDialog>

<p-toast key="myKey1" position="bottom-right"></p-toast>
