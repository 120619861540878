import { SelectItem } from "primeng/api";

import { environment } from "../../../environments/environment";
export const APP_API = "api/v1/";

export const CLIENTS_API = environment.baseUrl + APP_API + "locations";
export const APPOINTMENTS_API = environment.baseUrl + APP_API + "appointments";
export const LOCATIONS_API = environment.baseUrl + APP_API + "locations";

export const PARAM_STARTUPS_PATH = "/admin/startups/";
export const PARAM_STARTUPS_REVIEW_PATH = "/admin/reviews/";
export const PARAM_STARTUPS_LIKES_PATH = "/admin/likes/";

export const PARAM_WORKS_PATH = "/admin/settings/works/";
export const PARAM_APPOINTMENT_TYPE_PATH = "/admin/settings/appointment-type/";
export const PARAM_LOCATIONS_TYPE_PATH = "/admin/settings/locations/";
export const PARAM_FEATURES_PERSONS_PATH = "/admin/settings/features-persons/";
export const TOURS_REQUEST_API = "/admin/tours-requests/";

export const PARAM_FEATURES_TYPOLOGY_PATH =
  "/admin/settings/features-typology/";
export const PARAM_FEATURES_TECHNICAL_DATA_PATH =
  "/admin/settings/features-technical-data/";

export const PARAM_FEATURES_AREA_PATH = "/admin/settings/features-area/";
export const PARAM_FEATURES_SECTORS_PATH = "/admin/settings/features-sectors/";
export const PARAM_FEATURES_PROVINCES_PATH =
  "/admin/settings/features-provinces/";

export const PARAM_DESTINATIONS_AREA_PATH =
  "/admin/settings/features-destinations/";

export const PARAM_TAGS_PATH = "/admin/settings/tags/";
export const PARAM_TECHNOLOGY_PATH = "/admin/settings/technology/";

export const PARAM_PERSONAL_DATA_PATH = "/admin/settings/personal-data/";

export const PARAM_BRANDS_PATH = "/admin/settings/brands/";

export const PARAM_TECHNICAL_DATA_PATH = "/admin/settings/technical-data/";
export const PARAM_WAREHOUSES_PATH = "/admin/settings/warehouses/";
export const PARAM_CHECKINS_PATH = "/admin/checkins/";

export const PARAM_WAREHOUSE_MOVEMENTS_PATH = "/admin/warehouse-movements/";

export const PARAM_CHECKOUTS_PATH = "/admin/checkouts/";
export const PARAM_TIMESHEETS_PATH = "/admin/timesheets/";
export const PARAM_BILLINGS_PATH = "/admin/billings/";
export const PARAM_PURCHASE_ORDERS_PATH = "/admin/purchase-orders/";

export const PARAM_TRANSPORT_DOCUMENTS_PATH = "/admin/transport-documents/";
export const PARAM_QUOTES_PATH = "/admin/quotes/";
export const PARAM_ORDERS_PATH = "/admin/orders/";
export const PARAM_DOCUMENTS_PATH = "/admin/documents/";

export const PARAM_EMPLOYEE_TIMESHEETS_PATH = "/employee/timesheets/";
export const PARAM_EMPLOYEES_PATH = "/admin/employees/";
export const PARAM_CLIENTS_PATH = "/admin/clients/";
export const PARAM_CLIENTS_WORKS_PATH = "/admin/clients-works/";
export const PARAM_USERS_PATH = "/admin/users/";

export const PARAM_NEWS_PATH = "/admin/news/";
export const PARAM_PRODUCTS_PATH = "/admin/products/";

export const PARAM_PAGES_PATH = "/admin/pages/";
export const PARAM_CATEGORIES_PATH = "/admin/settings/categories/";
export const PARAM_TRUSTPILOT_PATH = "/admin/trustpilot/";

export const PARAM_SUPPORTS_PATH = "/admin/support/";
export const PARAM_PROJECTS_PATH = "/admin/projects/";
export const PARAM_PROJECTS_TIMESHEET_PATH = "/admin/projects/timesheet/";

export const PARAM_APPOINTMENTS_PATH = "/admin/appointments/";

export const FISCAL_CODE_VALIDATOR_REGEX =
  /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$/g;

export const API_DATE_FORMAT = "yyyy-MM-DD[T]HH:mm:ss";
export const CHIPS_DATE_FORMAT = "DD/MM/yyyy HH:mm";

export const PAGES: SelectItem[] = [
  {
    label: "Persone",
    value: "/admin/settings/features-persons",
    icon: "pi pi-list",
  },
  {
    label: "Tipologia",
    value: "/admin/settings/features-typology",
    icon: "pi pi-list",
  },
  { label: "Area", value: "/admin/settings/features-area", icon: "pi pi-list" },
  {
    label: "Destinazione",
    value: "/admin/settings/features-destinations",
    icon: "pi pi-list",
  },
  {
    label: "Settori",
    value: "/admin/settings/features-sectors",
    icon: "pi pi-list",
  },
  {
    label: "Province",
    value: "/admin/settings/features-provinces",
    icon: "pi pi-list",
  },
  {
    label: "Dati aggiuntivi",
    value: "/admin/settings/features-technical-data",
    icon: "pi pi-list",
  },
];

export const CLIENT_SETTING: SelectItem[] = [
  {
    label: "Settori",
    value: "/admin/settings/features-sectors",
    icon: "pi pi-list",
  },
  {
    label: "Province",
    value: "/admin/settings/features-provinces",
    icon: "pi pi-list",
  },
];

export const SETTING: SelectItem[] = [
  { label: "Tags", value: "/admin/settings/tags", icon: "pi pi-list" },
  {
    label: "Categories",
    value: "/admin/settings/categories",
    icon: "pi pi-list",
  },
  {
    label: "Technology",
    value: "/admin/settings/technology",
    icon: "pi pi-list",
  },
];

export const ANIMATIONS: SelectItem[] = [
  {
    label: "Persone",
    value: "/admin/settings/features-persons",
    icon: "pi pi-list",
  },
  {
    label: "Tipologia",
    value: "/admin/settings/features-typology",
    icon: "pi pi-list",
  },
  { label: "Area", value: "/admin/settings/features-area", icon: "pi pi-list" },
  {
    label: "Destinazione",
    value: "/admin/settings/features-destinations",
    icon: "pi pi-list",
  },
  {
    label: "Settori",
    value: "/admin/settings/features-sectors",
    icon: "pi pi-list",
  },
  {
    label: "Province",
    value: "/admin/settings/features-provinces",
    icon: "pi pi-list",
  },
  {
    label: "Dati aggiuntivi",
    value: "/admin/settings/features-technical-data",
    icon: "pi pi-list",
  },
];

export const DASHBOARD: SelectItem[] = [
  {
    label: "Persone",
    value: "/admin/settings/features-persons",
    icon: "pi pi-list",
  },
  {
    label: "Tipologia",
    value: "/admin/settings/features-typology",
    icon: "pi pi-list",
  },
  { label: "Area", value: "/admin/settings/features-area", icon: "pi pi-list" },
  {
    label: "Destinazione",
    value: "/admin/settings/features-destinations",
    icon: "pi pi-list",
  },
  {
    label: "Settori",
    value: "/admin/settings/features-sectors",
    icon: "pi pi-list",
  },
  {
    label: "Province",
    value: "/admin/settings/features-provinces",
    icon: "pi pi-list",
  },
  {
    label: "Dati aggiuntivi",
    value: "/admin/settings/features-technical-data",
    icon: "pi pi-list",
  },
];

export const TYPE_LIST: SelectItem[] = [
  { label: "Tutte", value: null },
  { label: "Visita", value: "Visita" },
  { label: "Intervento", value: "Intervento" },
  { label: "Visita di controllo", value: "Visita di controllo" },
  { label: "Amministrazione", value: "Amministrazione" },
  { label: "Altro", value: "Altro" },
];

export const STATUS_PROJECTS: SelectItem[] = [
  { label: "Tutte", value: null },
  { label: "Fase di Esecuzione", value: "Esecuzione" },
  { label: "Fase di Contrattazione", value: "Contrattazione" },
  { label: "Fase di Elaborazione", value: "Elaborazione" },
  { label: "Amministrazione", value: "Amministrazione" },
  { label: "Altro", value: "Altro" },
];

export const PRIORITY_LIST: SelectItem[] = [
  { label: "Da Fare", value: "1" },
  { label: "25 %", value: "2" },
  { label: "75 %", value: "3" },
  { label: "Done", value: "4" },
];

export const HOMEPAGE_OPTIONS = [
  { label: "Si", value: "1" },
  { label: "No", value: "0" },
];

export const FEATURED_OPTIONS = [
  { label: "Si", value: "1" },
  { label: "No", value: "0" },
];

export const STATE_PUBLISHED = [
  { label: "Nessun valore", value: "" },

  { label: "published", value: "published" },
  { label: "draft", value: "draft" },
  { label: "not published", value: "not-published" },
];

export const LEVEL_PUBLISHED = [
  { label: "Nessun valore", value: "" },

  { label: "low", value: "0" },
  { label: "middle", value: "1" },
  { label: "high", value: "2" },
];

export const NOTES_LIST: SelectItem[] = [
  { label: "Choose", value: "" },
  { label: "Problems with your website", value: "website_issues" },
  { label: "Waiting for more information", value: "waiting_info" },
  { label: "Technical difficulties", value: "technical_issues" },
  { label: "Lack of time", value: "no_time" },
  { label: "Content not ready", value: "content_not_ready" },
  { label: "Missing Images or Logo", value: "missing_images" },
  { label: "Malware detected in your website", value: "malware_detected" },
  { label: "Privacy concerns", value: "privacy_concerns" },
  { label: "Legal restrictions", value: "legal_restrictions" },
  { label: "Policy violations", value: "policy_violations" },
  { label: "Duplicate content", value: "duplicate_content" },
  { label: "Other", value: "other" },
];
export const STATUS_PRODUCTS: SelectItem[] = [
  { label: "published", value: "1" },
  { label: "not published", value: "0" },
];

export const COUNTRIES = [
  { label: "Afghanistan" },
  { label: "Albania" },
  { label: "Algeria" },
  { label: "American Samoa" },
  { label: "Andorra" },
  { label: "Angola" },
  { label: "Anguilla" },
  { label: "Antigua & Barbuda" },
  { label: "Argentina" },
  { label: "Armenia" },
  { label: "Aruba" },
  { label: "Australia" },
  { label: "Austria" },
  { label: "Azerbaijan" },
  { label: "Bahamas" },
  { label: "Bahrain" },
  { label: "Bangladesh" },
  { label: "Barbados" },
  { label: "Belarus" },
  { label: "Belgium" },
  { label: "Belize" },
  { label: "Benin" },
  { label: "Bermuda" },
  { label: "Bhutan" },
  { label: "Bolivia" },
  { label: "Bonaire" },
  { label: "Bosnia & Herzegovina" },
  { label: "Botswana" },
  { label: "Brazil" },
  { label: "British Indian Ocean Ter" },
  { label: "Brunei" },
  { label: "Bulgaria" },
  { label: "Burkina Faso" },
  { label: "Burundi" },
  { label: "Cambodia" },
  { label: "Cameroon" },
  { label: "Canada" },
  { label: "Canary Islands" },
  { label: "Cape Verde" },
  { label: "Cayman Islands" },
  { label: "Central African Republic" },
  { label: "Chad" },
  { label: "Channel Islands" },
  { label: "Chile" },
  { label: "China" },
  { label: "Christmas Island" },
  { label: "Cocos Island" },
  { label: "Colombia" },
  { label: "Comoros" },
  { label: "Congo" },
  { label: "Cook Islands" },
  { label: "Costa Rica" },
  { label: "Cote D'Ivoire" },
  { label: "Croatia" },
  { label: "Cuba" },
  { label: "Curacao" },
  { label: "Cyprus" },
  { label: "Czech Republic" },
  { label: "Denmark" },
  { label: "Djibouti" },
  { label: "Dominica" },
  { label: "Dominican Republic" },
  { label: "East Timor" },
  { label: "Ecuador" },
  { label: "Egypt" },
  { label: "El Salvador" },
  { label: "Equatorial Guinea" },
  { label: "Eritrea" },
  { label: "Estonia" },
  { label: "Ethiopia" },
  { label: "Falkland Islands" },
  { label: "Faroe Islands" },
  { label: "Fiji" },
  { label: "Finland" },
  { label: "France" },
  { label: "French Guiana" },
  { label: "French Polynesia" },
  { label: "French Southern Ter" },
  { label: "Gabon" },
  { label: "Gambia" },
  { label: "Georgia" },
  { label: "Germany" },
  { label: "Ghana" },
  { label: "Gibraltar" },
  { label: "Great Britain" },
  { label: "Greece" },
  { label: "Greenland" },
  { label: "Grenada" },
  { label: "Guadeloupe" },
  { label: "Guam" },
  { label: "Guatemala" },
  { label: "Guinea" },
  { label: "Guyana" },
  { label: "Haiti" },
  { label: "Hawaii" },
  { label: "Honduras" },
  { label: "Hong Kong" },
  { label: "Hungary" },
  { label: "Iceland" },
  { label: "India" },
  { label: "Indonesia" },
  { label: "Iran" },
  { label: "Iraq" },
  { label: "Ireland" },
  { label: "Isle of Man" },
  { label: "Israel" },
  { label: "Italy" },
  { label: "Jamaica" },
  { label: "Japan" },
  { label: "Jordan" },
  { label: "Kazakhstan" },
  { label: "Kenya" },
  { label: "Kiribati" },
  { label: "Korea North" },
  { label: "Korea South" },
  { label: "Kuwait" },
  { label: "Kyrgyzstan" },
  { label: "Laos" },
  { label: "Latvia" },
  { label: "Lebanon" },
  { label: "Lesotho" },
  { label: "Liberia" },
  { label: "Libya" },
  { label: "Liechtenstein" },
  { label: "Lithuania" },
  { label: "Luxembourg" },
  { label: "Macau" },
  { label: "Macedonia" },
  { label: "Madagascar" },
  { label: "Malaysia" },
  { label: "Malawi" },
  { label: "Maldives" },
  { label: "Mali" },
  { label: "Malta" },
  { label: "Marshall Islands" },
  { label: "Martinique" },
  { label: "Mauritania" },
  { label: "Mauritius" },
  { label: "Mayotte" },
  { label: "Mexico" },
  { label: "Midway Islands" },
  { label: "Moldova" },
  { label: "Monaco" },
  { label: "Mongolia" },
  { label: "Montserrat" },
  { label: "Morocco" },
  { label: "Mozambique" },
  { label: "Myanmar" },
  { label: "Nambia" },
  { label: "Nauru" },
  { label: "Nepal" },
  { label: "Netherland Antilles" },
  { label: "Netherlands (Holland, Europe)" },
  { label: "Nevis" },
  { label: "New Caledonia" },
  { label: "New Zealand" },
  { label: "Nicaragua" },
  { label: "Niger" },
  { label: "Nigeria" },
  { label: "Niue" },
  { label: "Norfolk Island" },
  { label: "Norway" },
  { label: "Oman" },
  { label: "Pakistan" },
  { label: "Palau Island" },
  { label: "Palestine" },
  { label: "Panama" },
  { label: "Papua New Guinea" },
  { label: "Paraguay" },
  { label: "Peru" },
  { label: "Philippines" },
  { label: "Pitcairn Island" },
  { label: "Poland" },
  { label: "Portugal" },
  { label: "Puerto Rico" },
  { label: "Qatar" },
  { label: "Republic of Montenegro" },
  { label: "Republic of Serbia" },
  { label: "Reunion" },
  { label: "Romania" },
  { label: "Russia" },
  { label: "Rwanda" },
  { label: "St Barthelemy" },
  { label: "St Eustatius" },
  { label: "St Helena" },
  { label: "St Kitts-Nevis" },
  { label: "St Lucia" },
  { label: "St Maarten" },
  { label: "St Pierre & Miquelon" },
  { label: "St Vincent & Grenadines" },
  { label: "Saipan" },
  { label: "Samoa" },
  { label: "Samoa American" },
  { label: "San Marino" },
  { label: "Sao Tome & Principe" },
  { label: "Saudi Arabia" },
  { label: "Senegal" },
  { label: "Serbia" },
  { label: "Seychelles" },
  { label: "Sierra Leone" },
  { label: "Singapore" },
  { label: "Slovakia" },
  { label: "Slovenia" },
  { label: "Solomon Islands" },
  { label: "Somalia" },
  { label: "South Africa" },
  { label: "Spain" },
  { label: "Sri Lanka" },
  { label: "Sudan" },
  { label: "Suriname" },
  { label: "Swaziland" },
  { label: "Sweden" },
  { label: "Switzerland" },
  { label: "Syria" },
  { label: "Tahiti" },
  { label: "Taiwan" },
  { label: "Tajikistan" },
  { label: "Tanzania" },
  { label: "Thailand" },
  { label: "Togo" },
  { label: "Tokelau" },
  { label: "Tonga" },
  { label: "Trinidad & Tobago" },
  { label: "Tunisia" },
  { label: "Turkey" },
  { label: "Turkmenistan" },
  { label: "Turks & Caicos Is" },
  { label: "Tuvalu" },
  { label: "Uganda" },
  { label: "Ukraine" },
  { label: "United Arab Emirates" },
  { label: "United Kingdom" },
  { label: "United States of America" },
  { label: "Uruguay" },
  { label: "Uzbekistan" },
  { label: "Vanuatu" },
  { label: "Vatican City State" },
  { label: "Venezuela" },
  { label: "Vietnam" },
  { label: "Virgin Islands (Brit)" },
  { label: "Virgin Islands (USA)" },
  { label: "Wake Island" },
  { label: "Wallis & Futana Is" },
  { label: "Yemen" },
  { label: "Zaire" },
  { label: "Zambia" },
  { label: "Zimbabwe" },
];

export const TIMESHEETS_TYPE: SelectItem[] = [
  { label: "Lavoro", value: "1" },
  { label: "Ferie", value: "2" },
  { label: "Permesso", value: "3" },
  { label: "Straordinari", value: "4" },
  { label: "Altro", value: "5" },
];

export const LANG_IT = {
  closeText: "Chiudi",
  prevText: "&#x3C;Prec",
  nextText: "Succ&#x3E;",
  currentText: "Oggi",
  monthNames: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ],
  monthNamesShort: [
    "Gen",
    "Feb",
    "Mar",
    "Apr",
    "Mag",
    "Giu",
    "Lug",
    "Ago",
    "Set",
    "Ott",
    "Nov",
    "Dic",
  ],
  dayNames: [
    "Domenica",
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
  ],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
  dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
  weekHeader: "Sm",
  dateFormat: "dd/mm/yy",
  firstDayOfWeek: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: "",
};

export const BLOG_LIST = [
  { label: "Blog", value: "1" },
  { label: "News", value: "0" },
];

export const STATE_LIST = [
  { label: "Si", value: "1" },
  { label: "No", value: "0" },
];

export const STATE_LIST_USER = [
  { label: "Si", value: "1" },
  { label: "No", value: "" },
];

export const BILLING_LIST = [
  { label: "Fattura", value: "Fattura" },
  { label: "Ricevuta", value: "Ricevuta" },
];

export const BUSINESS_STATE_LIST = [
  { label: "Business", value: "1" },
  { label: "Privato", value: "0" },
];

export const EMPLOYEE_TYPE_LIST = [
  { label: "Dipendente", value: "0" },
  { label: "Socio", value: "1" },
];

export const SEX_LIST: SelectItem[] = [
  { label: "Tutte", value: null },
  { label: "Uomo", value: "Uomo" },
  { label: "Donna", value: "Donna" },
];

export const ANNO_ITEMS: SelectItem[] = [
  { label: "nessuno", value: "" },
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" },
  { label: "2017", value: "2017" },
  { label: "2016", value: "2016" },
  { label: "2015", value: "2015" },
  { label: "2014", value: "2014" },
  { label: "2013", value: "2013" },
  { label: "2012", value: "2012" },
  { label: "2011", value: "2011" },
  { label: "2010", value: "2010" },
  { label: "2009", value: "2009" },
];

export const TIPO_CONTENUTO_MILTILINGUA_ITEMS: SelectItem[] = [
  { label: "...", value: "..." },
  { label: "Attività", value: "Attività" },
  { label: "Contenuti Generici", value: "Contenuti Generici" },
  { label: "Gallerie", value: "Gallerie" },
  { label: "Servizi", value: "Servizi" },
  { label: "Video", value: "Video" },
];

export const TIPO_CONTENUTO_ITEMS: SelectItem[] = [
  { label: "...", value: "..." },
  { label: "Attività", value: "Attività" },
  { label: "Contenuti Generici", value: "Contenuti Generici" },
  { label: "Documenti", value: "Documenti" },
  { label: "News", value: "News" },
  { label: "Sedi", value: "Sedi" },
  { label: "Servizi", value: "Servizi" },
  { label: "Settori", value: "Settori" },
  { label: "Sotto Attività", value: "Sotto Attività" },
  { label: "Sotto Servizi", value: "Sotto Servizi" },
  { label: "Storie", value: "Storie" },
];

export const MENU_ITEMS: any[] = [
  {
    link: "allegati",
    not: "",
    title: "Allegati",
    icon: "fa fa-paperclip",
    description: "Gestione dei Allegati",
  },
  {
    link: "attivita",
    not: "sotto",
    title: "Attività",
    icon: "fa fa-cogs",
    description: "Gestione delle Attività",
  },
  {
    link: "blog",
    not: "",
    title: "Blog",
    icon: "fa fa-book",
    description: "Blog",
  },
  {
    link: "contenuti-generici",
    not: "",
    title: "Contenuti Generici",
    icon: "fa fa-connectdevelop",
    description: "Gestione dei Contenuti Generici",
  },

  {
    link: "careers",
    not: "",
    title: "Careers",
    icon: "fa fa-file-o",
    description: "Offerte di lavoro",
  },
  {
    link: "formrequestcareer",
    not: "",
    title: " Curriculum",
    icon: "fa fa-envelope-o",
    description: "Curriculum",
  },

  {
    link: "documenti",
    not: "",
    title: "Documenti",
    icon: "fa fa-file-o",
    description: "Gestione dei Documenti",
  },
  {
    link: "multilingue",
    not: "",
    title: "Multilingue",
    icon: "fa fa-language",
    description: "Gestione delle Multilingue",
  },
  {
    link: "news",
    not: "",
    title: "News",
    icon: "fa fa-newspaper-o",
    description: "Gestione delle News",
  },
  {
    link: "sedi",
    not: "",
    title: "Sedi",
    icon: "fa fa-home",
    description: "Gestione delle Sedi",
  },
  {
    link: "servizi",
    not: "sotto",
    title: "Servizi",
    icon: "fa fa-server",
    description: "Gestione dei Servizi",
  },
  {
    link: "settori",
    not: "",
    title: "Settori",
    icon: "fa fa-wpforms",
    description: "Gestione dei Settori",
  },
  {
    link: "sottoattivita",
    not: "",
    title: "Sotto Attività",
    icon: "fa fa-cog",
    description: "Gestione delle Sotto Attività",
  },
  {
    link: "sottoservizi",
    not: "",
    title: "Sotto Servizi",
    icon: "fa fa-th-list",
    description: "Gestione dei Sotto Servizi",
  },
  {
    link: "storie",
    not: "",
    title: "Storia",
    icon: "fa fa-history",
    description: "Gestione della Storia",
  },
  {
    link: "video",
    not: "",
    title: "Video",
    icon: "fa fa-youtube",
    description: "Gestione dei Video",
  },
  {
    link: "gallerie",
    not: "",
    title: "Gallerie di Foto",
    icon: "fa fa-picture-o",
    description: "Gestione delle Gallerie di Foto",
  },
  {
    link: "formrequest",
    not: "",
    title: "Richieste",
    icon: "fa fa-envelope-o",
    description: "Richieste",
  },
];

export const APP_VERSION = "2.0";
export const EDITOR_CONFIGURATION =
  'selector:"textarea", plugins: "table",  menubar: "false", height: 400, toolbar: "table | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent"';
