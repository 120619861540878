import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CrudService } from './crud.service';
import { Startups_markets } from '../models/startups_markets';

@Injectable({
  providedIn: 'root'
})

export class StartupsMarketsService extends CrudService<Startups_markets, number> {

  constructor(protected _http: HttpClient) {
    super(_http, `${environment.baseUrl}/startups_markets`);
  }

 


}



