import { Component, ElementRef, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
  FormGroup,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ViewChild } from "@angular/core";
import { Clients } from "../../../models/clients";
import { Category } from "../../../models/category";
import { UntypedFormControl } from "@angular/forms";
import { CategoryService } from "../../../services/categories.service";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import * as moment from "moment";
import {
  FISCAL_CODE_VALIDATOR_REGEX,
  SEX_LIST,
  STATE_LIST,
  BUSINESS_STATE_LIST,
  STATE_PUBLISHED,
  BLOG_LIST,
  LEVEL_PUBLISHED,
} from "../../constants/constants";
import { ComuniService } from "src/app/services/comuni.service";
import { Comuni } from "src/app/models/comuni";
import { Location } from "@angular/common";
import { AppointmentsService } from "src/app/services/appointments.service";
import { Appointments } from "src/app/models/appointments";
import { BillingsService } from "src/app/services/billings.service";
import { Billings } from "src/app/models/billings";
import { PersonalDataService } from "src/app/services/personal_data.service";
import { Personal_data } from "src/app/models/personal_data";
import { NgxSpinnerService } from "ngx-spinner";
import { NewsService } from "src/app/services/news.service";
import { SlideshowService } from "src/app/services/slideshow.service";
import { Slideshow } from "src/app/models/slideshow";
import { DocumentsService } from "src/app/services/documents.service";
import { News } from "src/app/models/news";
import { Tours } from "src/app/models/tours";
import { Tags } from "src/app/models/tags";

@Component({
  selector: "app-news-form",
  templateUrl: "./news-form.component.html",
})
export class NewsFormComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInputVariable: ElementRef;

  pageTitle: string;
  error: string;
  uploadError: string;
  imagePath: any;
  imagePathFirst: any;
  imagePathSecond: any;
  clients: Clients;
  client: Clients;
  slideshows: any = [];
  slideshow: Slideshow;
  documents: any = [];
  tours: any = [];
  clientsList: any = [];
  clientList: Clients;
  selectedtours: SelectItem[] = [];

  categories: any = [];
  category: Category;
  checked: boolean = true;
  selectedValue: string;
  comuni: Comuni;

  blogForm: UntypedFormGroup;
  typeList: any[];
  featuredTours: Tours[] = [];
  
  selectedTours: SelectItem[] = [];
  selectedItemDisplay: string;
  featuredTags: Tags[] = [];
  selectedTags: SelectItem[] = [];

  cities: Clients[];
  format1: string = "";
  format2: string = "";
  selectedCity: Clients;
  selectedCategories: Category;
  selectedDate: Date;
  date: Date;
  appointments: any = [];
  is_featured = "0";
  deleteButton: boolean;
  billings: any = [];
  billing: Billings;
  currentUser: any;
  addForm: UntypedFormGroup;
  rows: UntypedFormArray;
  itemForm: UntypedFormGroup;
  element: News;
  stateOptions: any[];
  blogOptions: any[];

  cols: any[];
  exportColumns: any[];
  _selectedColumns: any[];
  businessStateOptions: any[];
  personal_datas: any = [];
  personal_data: Personal_data;
  personName: string;
  selectedStatePublished: SelectItem[] = [];
  selectedLevelPublished: SelectItem[] = [];
  categoriesList: Category[] = [];
  statePublished: any[];
  levelPublished: any[];
  trackByFn(index, item) {
    return item.id;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private newsService: NewsService,
    private categoryService: CategoryService,
    private _location: Location,
    private appointmentsService: AppointmentsService,
    private billingsService: BillingsService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (this.date) {
      this.selectedDate = new Date(this.date);
    }
    this.currentUser = JSON.parse(localStorage.getItem("currentUser") || "[]");
    this.stateOptions = STATE_LIST;
    this.blogOptions = BLOG_LIST;
    this.statePublished = STATE_PUBLISHED;
    this.levelPublished = LEVEL_PUBLISHED;

    const userId = this.currentUser.user_id;
  }

  ngOnInit() {
    this.spinner.show();
    this.getCategories();

    const id = this.route.snapshot.paramMap.get("id");
    if (id) {
      this.pageTitle = "Modifica News";

      this.newsService.getId(+id).subscribe((res) => {
        this.element = res;

        this.blogForm.patchValue({
          page_title: res.page_title,
          description_1: res.description_1,
          description_2: res.description_2,
          description_3: res.description_3,
          page_seotitle: res.page_seotitle,
          page_seokeywords: res.page_seokeywords,
          page_seodescription: res.page_seodescription,
          page_url: res.page_url,
          status: res.status,
          date_created: res.date_created,
          is_featured: res.is_featured,
          tags: res.tags.split(","),
          level: res.level,
          is_active: res.is_active,
          id: res.id,
        });

        this.imagePath = res.image;

      });
    } else {
      this.deleteButton = false;
      this.pageTitle = "Aggiungi News";
    }

    this.blogForm = this.fb.group({
      id: [""],
      page_title: ["", Validators.required],
      description_1: [""],
      description_2: [""],
      description_3: [""],
      page_seotitle: [""],
      page_seokeywords: [""],
      page_seodescription: [""],
      page_url: [""],
      is_featured: [""],
      level: [""],
      date_created: ['', [dateValidator()]],
      tags: [""],
      status: [""],
      is_active: ["0"],
    });


    this.spinner.hide();

    
  }



  get f(): { [key: string]: AbstractControl } {
    return this.blogForm.controls;
  }



  public getCategories() {
    const paramsCategories = [];
    this.categoryService.list(paramsCategories).subscribe((pData) => {
      this.categoriesList = pData;
    });

  }


  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.blogForm.get('image').setValue(file);

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imagePath = reader.result;
      };
    }
  }

  removeImageFile() {
    this.imagePath = '';
    console.log(this.myInputVariable.nativeElement.files);
    this.myInputVariable.nativeElement.value = '';
    console.log(this.myInputVariable.nativeElement.files);
  }

  onDelete(id: number, title: string) {
    this.confirmationService.confirm({
      message: "Sei sicuro di volerlo cancellare",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.newsService.delete(+id).subscribe(
          (res) => {
            console.log(res);
            this.messageService.add({
              key: "cancel",
              severity: "success",
              summary: "Attenzione",
              detail: "Cancellazione avvenuto con successo",
            });
            this._location.back();
          },
          (error) => {
            this.error = error;
            this.messageService.add({
              key: "cancel",
              severity: "warn",
              summary: "Attenzione",
              detail: "Errore backend",
            });
          }
        );
      },
    });
  }

  get title() {
    return this.blogForm.get("title");
  }

  get id() {
    return this.blogForm.get("id").value;
  }

  get description() {
    return this.blogForm.get("description");
  }

  get dateCreated() {
    return this.blogForm.get('date_created');
  }

  selectDivision(e) {
    this.selectedTours = e.value.map((item) => item.name);
    if (this.selectedTours.length > 3) {
      this.selectedItemDisplay = `Selected items: ${this.selectedTours.join(', ')}`;
    } else {
      this.selectedItemDisplay = '';
    }
  }



  onSubmit() {
    const formData = new FormData();
    formData.append("page_title", this.blogForm.get("page_title").value);
    formData.append("page_url", this.blogForm.get("page_url").value);
    formData.append("status", this.blogForm.get("status").value);
    formData.append("is_active", this.blogForm.get("is_active").value);
    formData.append("level", this.blogForm.get("level").value);
    formData.append("tags", this.blogForm.get("tags").value);
    formData.append("description_1", this.blogForm.get("description_1").value);
    formData.append("description_2", this.blogForm.get("description_2").value);
    formData.append("description_3", this.blogForm.get("description_3").value);
    formData.append("page_seotitle", this.blogForm.get("page_seotitle").value);
    formData.append("page_seokeywords", this.blogForm.get("page_seokeywords").value);
    formData.append("page_seodescription", this.blogForm.get("page_seodescription").value);
    formData.append("is_featured", this.blogForm.get("is_featured").value);
    formData.append("date_created", this.blogForm.get("date_created").value);


    
    const id = this.blogForm.get("id").value;

    if (id) {
      this.newsService.update(formData, +id).subscribe(
        (res) => {
          if (res.status == "error") {
            this.uploadError = res.message;
            this.messageService.add({
              key: "myKey1",
              severity: "warn",
              summary: "Attenzione",
              detail: "Salvato senza sucesso",
            });
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Attenzione",
              detail: "Salvato con sucesso",
            });
            // this._location.back();
          }
        },
        (error) => (this.error = error)
      );
    } else {
      this.newsService.create(formData).subscribe(
        (res) => {
          if (res.status === "error") {
            this.uploadError = res.message;
          } else {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              summary: "Attenzione",
              detail: "Salvato con sucesso",
            });
            this.router.navigate(["/admin/news"]);
          }
        },
        (error) => (this.error = error)
      );
    }
  }
}

export function codFisc(c: UntypedFormControl): { [s: string]: boolean } {
  if (c.value && !c.value.match(FISCAL_CODE_VALIDATOR_REGEX)) {
    return { invalidCF: true };
  }
}


export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/; // Matches YYYY-MM-DD format
    const isValid = datePattern.test(control.value);

    return isValid ? null : { invalidDateTime: true }; // Error if format doesn't match
  };
}