<app-topnav></app-topnav>

<form [formGroup]="blogForm">

    <div class="top-main-wrapper">

        <div class="dash-header">
            <div class="dash-title">

                <div class="p-field p-grid">
                    <div class="p-col-8 p-md-8">
                        <h3>Startup <span class="normal-font" *ngIf="id">/ {{tourTitle}}</span> </h3>


                    </div>
                    <div class="p-col-4 p-md-4">
                        <button pButton pRipple icon="pi pi-save" label="salva"
                            class="p-button pull-right p-button-success p-mr-2" (click)="onSubmit()"
                            [disabled]="!blogForm.valid"></button>


                        <button type="button" pButton pRipple icon="pi pi-chevron-left" label=" indietro"
                            class="p-button p-button-back pull-right  p-mr-2"
                            [routerLink]="['/admin/startups/']"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="main-inner">

        <div class="p-fluid">
            <div class="p-field p-grid">




                <div class="p-col-12 p-md-12">


                    <p-tabView>


                        <p-tabPanel header="Informazioni">
                            <div class="p-grid">

                                <div class="p-col-12 p-md-8">
                                    <p-card>


                                        <div class="p-fluid">

                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Startup Name<span
                                                        class="required"></span></label>
                                                <div class="p-col-12">

                                                    <input type="text" [style]="{ width: '100%' }" pInputText
                                                        formControlName="startup_name">

                                                    <!-- <input type="text" [style]="{ width: '100%' }" [(ngModel)]="data" value="{{rows.value | json}}" pInputText formControlName="data" >-->

                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-fluid">

                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Startup Url<span
                                                        class="required"></span></label>
                                                <div class="p-col-12">
                                                    <div class="p-inputgroup">

                                                        <input type="text" [style]="{ width: '100%' }" pInputText
                                                            formControlName="startup_url">

                                                        <!-- <input type="text" [style]="{ width: '100%' }" [(ngModel)]="data" value="{{rows.value | json}}" pInputText formControlName="data" >-->
                                                        <button type="button" pButton pRipple label="website"
                                                            class="p-button p-button-success pull-right  p-mr-2"
                                                            (click)="goToUrl()"></button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Headline / Slogan <span
                                                        class="required"></span></label>
                                                <div class="p-col-12">

                                                    <input type="text" [style]="{ width: '100%' }" pInputText
                                                        formControlName="startup_headline">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Country <span class="required"></span></label>
                                                <div class="p-col-12">
                                                    <p-dropdown [options]="countriesList" [style]="{ width: '100%' }"
                                                        formControlName="country" [(ngModel)]="selectedCountry"
                                                        optionLabel="label" optionValue="label">
                                                    </p-dropdown>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-field p-grid">
                                            <label class="p-col-12">Category <span class="required">*</span></label>
                                            <div class="p-col-12">

                                                <p-multiSelect [options]="categoriesList" selectionLimit="1"
                                                    [style]="{ width: '100%' }" formControlName="category_id"
                                                    [(ngModel)]="selectedCategories" optionLabel="name"
                                                    optionValue="id">
                                                </p-multiSelect>

                                            </div>
                                        </div>


                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Slug <span class="required"></span></label>
                                                <div class="p-col-12">
                                                    <div class="p-inputgroup">
                                                        <span
                                                            class="p-inputgroup-addon">startupinspire.com/startup/</span>

                                                        <input type="text" [style]="{ width: '60%' }" pInputText
                                                            formControlName="page_url">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">User ID <span class="required"></span></label>
                                                <div class="p-col-12">

                                                    <input type="text" [style]="{ width: '100%' }" pInputText
                                                        formControlName="user_id">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Status <span class="required"></span></label>
                                                <div class="p-col-12">

                                                    <input type="text" [style]="{ width: '100%' }" pInputText
                                                        formControlName="status">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Description <span
                                                        class="required"></span></label>
                                                <div class="p-col-12">


                                                    <p-editor formControlName="startup_description"
                                                        [style]="{ height: '220px' }">
                                                        <ng-template pTemplate="header">
                                                            <span class="ql-formats">
                                                                <button type="button" class="ql-bold"
                                                                    aria-label="Bold"></button>
                                                                <button type="button" class="ql-italic"
                                                                    aria-label="Italic"></button>
                                                                <button type="button" class="ql-underline"
                                                                    aria-label="Underline"></button>
                                                                <button type="button" class="ql-clean"
                                                                    aria-label="clean">
                                                                </button>

                                                                <button type="button" class="ql-link" aria-label="Link">
                                                                </button>
                                                            </span>
                                                        </ng-template>
                                                    </p-editor>



                                                </div>
                                            </div>
                                        </div>


                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Technology Stack <span
                                                        class="required"></span></label>
                                                <div class="p-col-12">




                                                    <p-editor formControlName="startup_technology"
                                                        [style]="{ height: '220px' }">
                                                        <ng-template pTemplate="header">
                                                            <span class="ql-formats">
                                                                <button type="button" class="ql-bold"
                                                                    aria-label="Bold"></button>
                                                                <button type="button" class="ql-italic"
                                                                    aria-label="Italic"></button>
                                                                <button type="button" class="ql-underline"
                                                                    aria-label="Underline"></button>
                                                                <button type="button" class="ql-clean"
                                                                    aria-label="clean">
                                                                </button>

                                                                <button type="button" class="ql-link" aria-label="Link">
                                                                </button>
                                                            </span>
                                                        </ng-template>
                                                    </p-editor>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Email <span class="required"></span></label>
                                                <div class="p-col-12">
                                                    <input type="text" [style]="{ width: '100%' }" pInputText
                                                        formControlName="startup_email">
                                                </div>
                                            </div>
                                        </div>


                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Linkedin <span class="required"></span></label>
                                                <div class="p-col-12">

                                                    <input type="text" [style]="{ width: '100%' }" pInputText
                                                        formControlName="linkedin">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Twitter <span class="required"></span></label>
                                                <div class="p-col-12">

                                                    <input type="text" [style]="{ width: '100%' }" pInputText
                                                        formControlName="twitter">

                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-fluid">
                                            <div class="p-field p-grid">
                                                <label class="p-col-12">Producthunt <span
                                                        class="required"></span></label>
                                                <div class="p-col-12">

                                                    <input type="text" [style]="{ width: '100%' }" pInputText
                                                        formControlName="producthunt">

                                                </div>
                                            </div>
                                        </div>




                                        <div class="form-group form-row">
                                            <label class="p-md-3"></label>
                                            <div class="p-md-9">
                                                <input type="hidden" formControlName="id">
                                                <input type="hidden" formControlName="user_id">

                                            </div>
                                        </div>

                                    </p-card>
                                </div>



                                <div class="p-col-12 p-md-4">

                                    <p-card>
                                        <div class="p-grid">
                                            <div class="p-col-12 p-md-12 p-mb-3">

                                                <ng-container *ngIf="user">
                                                    <button pButton pRipple label="view" icon="pi pi-eye"
                                                        class="p-button p-button-outline pull-right"
                                                        [routerLink]="['/admin/users/edit', user.id]"></button>

                                                    <h4 class="p-pb-2 p-mb-0">{{user.display_name}}</h4>
                                                    <p class="small">{{user.user_email}}</p>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <p-tag *ngIf="user" class="cp p-mr-2" severity="default">ID {{user.id}}</p-tag>

                                        <p-tag *ngIf="startupStatus == 1" class="cp" severity="success"
                                            (click)="unpublished(id)">published</p-tag>

                                        <p-tag *ngIf="startupStatus == 0" class="cp" severity="danger"
                                            (click)="published(id)">unpublished</p-tag>
                                    </p-card>
                                    <p-card>

                                        <div class="p-fluid">
                                            <div class="p-field p-grid">

                                                <div class="p-col-12 p-md-12">
                                                    <div class="button_outer">
                                                        <div class="btn_upload">
                                                            <input #myInput type="file" id="image"
                                                                (change)="onSelectedFile($event)" />
                                                            Upload Image
                                                        </div>

                                                        <div *ngIf="f.image.errors" class="invalid-feedback">
                                                            <div *ngIf="f.image.errors.required">Fullname is required
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <p class="detail">600 x 600px</p>
                                                    <div [innerHTML]="uploadError" class="error"></div>
                                                    <div *ngIf="imagePath">
                                                        <br />
                                                        <span class="image-container">
                                                            <img [src]="imagePath" class="img-responsive">

                                                            <button pButton pRipple icon="pi pi-trash"
                                                                class="imgcancel p-button p-button-primary p-mr-2"
                                                                (click)="removeImageFile($event)"></button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </p-card>
                                </div>
                            </div>
                        </p-tabPanel>
                        <ng-container *ngIf="element">

                            <p-tabPanel header="Slideshow" *ngIf="element.pro">
                                <app-upload [element]="element"></app-upload>
                            </p-tabPanel>

                        </ng-container>



                    </p-tabView>



                </div>



            </div>

        </div>
    </div>
</form>




<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" #cd>

    <p-footer>
        <button type="button" pButton icon="fa fa-close" class="p-button p-button-danger p-mr-2" label="No"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="fa fa-check" class="p-button p-button-success p-mr-2" label="Si"
            (click)="cd.accept()"></button>
    </p-footer>

</p-confirmDialog>

<p-toast key="myKey1" position="bottom-right"></p-toast>